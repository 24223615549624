

  export default {
    name: 'VideoComponent',

    props: {
        self_hosted_video_url: {
            type: String,
            default: "",
        },
        other_video_link: {
            type: String,
            default: "",
        },
        youtube_link: {
            type: String,
            default: "https://www.youtube.com/embed/9bEEZmVJggI",
        },
        
    },

    data() {
      return {
      }
    },
  }
