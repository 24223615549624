export const state = () => ({
  modals: {
    showConfirmationModal: false,
    showLeadFormModal: false,
    showContactUsFormModal: false,
    showProfileMenuModal: false,
    showWechatModal: false,
    showProductDisclaimerModal: false,
    showBlogDisclaimerModal: false,
    showDecoderCTAModal: false,
    showPopupBannerModal: false
  },
  blogDisclaimerModalData: {},
  decoderCTAModalData: {},
  loginModalEntryPoint: '',
  showDisplayMobileFilter: {
    isOpen: false,
    mode: 'default'
  },
  videoModalLink: '',
  showVideoModal: false,
  drifterZoneVideoModalLink: '',
  showDrifterZoneVideoModal: false,
});

export const mutations = {
  setShowConfirmationModal(state, data) {
    state.modals.showConfirmationModal = data;
  },
  setShowLeadFormModal(state, data) {
    state.modals.showLeadFormModal = data;
  },
  setShowContactUsFormModal(state, data) {
    state.modals.showContactUsFormModal = data;
  },
  setShowProfileMenuModal(state, data) {
    state.modals.showProfileMenuModal = data;
  },
  setShowWechatModal(state, data) {
    state.modals.showWechatModal = data;
  },
  setShowVideoModal(state, data) {
    state.showVideoModal = data;
  },
  setShowDrifterZoneVideoModal(state, data) {
    state.showDrifterZoneVideoModal = data;
  },
  setShowProductDisclaimerModal(state, data) {
    state.modals.showProductDisclaimerModal = data;
  },
  setShowBlogDisclaimerModal(state, data) {
    state.modals.showBlogDisclaimerModal = data;
  },
  setBlogDisclaimerModalData(state, data) {
    state.blogDisclaimerModalData = Object.assign({}, state.blogDisclaimerModalData, data);
  },
  setLoginModalEntryPoint(state, data) {
    state.loginModalEntryPoint = data;
  },
  setDisplayMobileFilter(state, data) {
    state.showDisplayMobileFilter.isOpen = data.isOpen;
    state.showDisplayMobileFilter.mode = data.mode;
  },
  setVideoModalLink(state, data) {
    state.videoModalLink = data;
  },
  setDrifterZoneVideoModalLink(state, data) {
    state.drifterZoneVideoModalLink = data;
  },
  setShowDecoderCTAModal(state, data) {
    state.modals.showDecoderCTAModal = data;
  },
  setDecoderCTAModalData(state, data) {
    state.decoderCTAModalData = Object.assign({}, state.decoderCTAModalData, data);
  },
  setShowPopupBannerModal(state, data) {
    state.modals.showPopupBannerModal = data;
  }
}

export const getters = {
  showConfirmationModal: (state) => {
    return state.modals.showConfirmationModal;
  },
  showLeadFormModal: (state) => {
    return state.modals.showLeadFormModal;
  },
  showContactUsFormModal: (state) => {
    return state.modals.showContactUsFormModal;
  },
  showProfileMenuModal: (state) => {
    return state.modals.showProfileMenuModal;
  },
  showWechatModal: (state) => {
    return state.modals.showWechatModal;
  },
  showVideoModal: (state) => {
    return state.showVideoModal;
  },
  showDrifterZoneVideoModal: (state) => {
    return state.showDrifterZoneVideoModal;
  },
  showDecoderCTAModal: (state) => {
    return state.modals.showDecoderCTAModal;
  },
  decoderCTAModalData: (state) => {
    return state.decoderCTAModalData;
  },
  showProductDisclaimerModal: (state) => {
    return state.modals.showProductDisclaimerModal;
  },
  showBlogDisclaimerModal: (state) => {
    return state.modals.showBlogDisclaimerModal;
  },
  blogDisclaimerModalData: (state) => {
    return state.blogDisclaimerModalData;
  },
  anyModalIsOn: (state) => {
    for (const key of Object.keys(state.modals)) {
      if (state.modals[key]) return true;
    }
    return false;
  },
  loginModalEntryPoint: (state) => {
    return state.loginModalEntryPoint;
  },
  showDisplayMobileFilter: (state) => {
    return state.showDisplayMobileFilter;
  },
  getVideoModalLink: (state) => {
    return state.videoModalLink;
  },
  getDrifterZoneVideoModalLink: (state) => {
    return state.drifterZoneVideoModalLink;
  },
  showPopupBannerModal: (state) => {
    return state.modals.showPopupBannerModal;
  }
};