


export default {
  data() {
    return {
      intervalId: null
    }
  },
  computed: {
    OAuthBaseUrl() {
      // const base = 'https://dev-auth.tenlife.asia';
      const base = process.env.AUTH_BASE_URL;
      return base;
    },
    // clientId() {
    //   return process.env.NUXT_TENLIFE_SSO_CLIENT_ID;
    // }
    isAuthenticated () {
      return this.$store.state.auth.loggedIn;
    },
  },
  beforeDestroy() {
    window.clearInterval(this.intervalId)
    // console.log("cleared interval: "+ this.intervalId);
  },
  methods: {
    init(){
      if(this.isAuthenticated)
      {
        const targetOrigin = this.OAuthBaseUrl;

        const iframe = document.getElementById("check-session-iframe");
        const iframeWindow = iframe.contentWindow;     

        const sessionState = this.$auth.$storage.getUniversal('session_state');
        const clientId = this.$auth.$storage.getUniversal('client_id');
        // not sure if this is right to do so but sometimes it fires several times with null clientId and sessionState
        // causing the application go to logout stage
        console.debug('checking clientid and session state:')
        console.debug(clientId);
        console.debug(sessionState);
        
        const data = "client_id="+clientId+"&session_state="+sessionState;   

        window.addEventListener("message", (event) => {   
          // since message is a boardcast, need to prevent windows listen its own message
          if (event.origin === window.location.origin) return;
          // Check if the origin matches, i.e. do not listen to other origin except from sso    
          if (event.origin !== targetOrigin){
            return;
          } 
          // Check if the message is "changed" or "unchanged"    
          if (event.data === "changed") {
            this.logout();
          } else if (event.data === "unchanged") {      
            // The session state has not changed, do nothing    
          }   
        }, {once : false});
        if(clientId && sessionState)
        {
          iframeWindow.postMessage(data, targetOrigin);
          this.intervalId = setInterval(
            () => {
              iframeWindow.postMessage(data, targetOrigin);
            }, 
            60000
          );
        }
      }
    },
    logout() {
      console.debug("Logout")
      this.$auth.logout();
    }
  },
}

