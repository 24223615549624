
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      componentRendered: false,
      promotionBannerLoadedCount: 0,
      listenerTasks: [],
    };
  },


  computed: {

  },

  created() {
    // console.info('created (tracking)...')
  },
  mounted() {
    // console.info('mounted (tracking)...')
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  beforeUpdate() {
    // console.info('beforeUpdate (tracking)...')
  },
  updated() {
    // console.info('updated (tracking)...')
    this.componentRendered = true
  },

  methods: {
    async sendEventTracking(params) {
      if (params.a !== '') {
        return await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (window.eventTracking) {
              clearInterval(interval);
              // console.log('sendEventTracking....', params);
              window.eventTracking(params);
              resolve();
            }
          }, 1000);
        });
      }
    },

    setEventTrackingListeners() {
      const that = this;
      const aTagElementsOfBlogSingle = document.querySelectorAll('.blog-content a');
      aTagElementsOfBlogSingle.forEach(function (el) {
        return el.addEventListener('click', that.handleEventTracking_blogSingle);
      });
      const aTagElementsOfProductCompactCard = document.querySelectorAll('.product-compact-card a');
      aTagElementsOfProductCompactCard.forEach(function (el) {
        return el.addEventListener('click', that.handleEventTracking_productCard);
      });
      const aTagElementsOfFiveStarCatPage = document.querySelectorAll('.five-star-products-container a');
      aTagElementsOfFiveStarCatPage.forEach(function (el) {
        return el.addEventListener('click', that.handleEventTracking_fiveStarCatPage);
      });
      const aTagElementsOfDecoderMain = document.querySelectorAll('.product-pages-slider-wrapper a');
      aTagElementsOfDecoderMain.forEach(function (el) {
        return el.addEventListener('click', that.handleEventTracking_decoderMain);
      });
      const aTagElementsOfProductMainPage = document.querySelectorAll('.product-container a');
      aTagElementsOfProductMainPage.forEach(function (el) {
        return el.addEventListener('click', that.handleEventTracking_productMainPage);
      });
      const aTagElementsOfProductDetailPage = document.querySelectorAll('.product-detail-wrapper a');
      aTagElementsOfProductDetailPage.forEach(function (el) {
        return el.addEventListener('click', that.handleEventTracking_productDetailPage);
      });
      const aTagElementsOfFavProductListing = document.querySelectorAll('.my-favourite .productListing a');
      aTagElementsOfFavProductListing.forEach(function (el) {
        return el.addEventListener('click', that.handleEventTracking_favProductListing);
      });
      const aTagElementsOfCompareButton = document.querySelectorAll('.sticky-comparison button');
      aTagElementsOfCompareButton.forEach(function (el) {
        return el.addEventListener('click', that.handleEventTracking_compareButton);
      });

      
      this.listenerTasks.forEach((task) => {
        if (task) {
          task()
        }
      });

    },
    initEventTrackingParams() {
      return {
        a: '',
        c: '',
        l: '',
        cd: {
          login_status: this.loginBoolean,
        },
      };
    },
    getCurrentLink() {
      return this.$route.name;
    },
    getRouteName() {
      return this.$route.name.substring(0, this.$route.name.indexOf('___'));
    },
    getPlacementCodeByRouteName() {
      if (this.getRouteName() === 'life-stage') return 'life-stage';
      if (this.getRouteName() === 'advisor-service') return 'broker-page';
      if (this.getRouteName() === 'blog-slug') return 'blog-single';
      if (this.getRouteName() === 'blog-category-slug') return 'blog-listing';
      if (this.getRouteName() === 'blog') return 'blog-listing';
      if (this.getRouteName() === 'insurance-guide-slug') {
        if (this.$route?.params?.slug === 'vhis') return 'vhis-guide';
        if (this.$route?.params?.slug === 'medical') return 'medical-guide';
        if (this.$route?.params?.slug === 'critical-illness') return 'critical-illness-guide';
        if (this.$route?.params?.slug === 'savings') return 'savings-guide';
        if (this.$route?.params?.slug === 'annuity') return 'annuity-guide';
        if (this.$route?.params?.slug === 'qdap') return 'qdap-guide';
        if (this.$route?.params?.slug === 'life') return 'life-guide';
        if (this.$route?.params?.slug === 'term-cancer') return 'term-cancer-guide';
        if (this.$route?.params?.slug === 'home') return 'home-guide';
      }
      if (this.getRouteName().includes('products-deferred-annuity')) return 'decoder-qdap';
      if (this.getRouteName().includes('products-immediate-annuity')) return 'decoder-immediate-annuity';
      if (this.getRouteName().includes('products-long-term-savings')) return 'decoder-long-term-saving';
      if (this.getRouteName().includes('products-medical')) {
        if (this.$route?.query?.vhistype === '44' || this.$route?.query?.vhistype === '45') return 'decoder-vhis';
        if (this.$route?.query?.isvhis === '1') return 'decoder-vhis';
        return 'decoder-medical';
      }
      if (this.getRouteName().includes('products-short-term-savings')) return 'decoder-short-term-saving';
      if (this.getRouteName().includes('products-term-cancer')) return 'decoder-term-cancer';
      if (this.getRouteName().includes('products-term-critical-illness')) return 'decoder-term-ci';
      if (this.getRouteName().includes('products-term-life')) return 'decoder-term-life';
      if (this.getRouteName().includes('products-whole-life-critical-illness')) return 'decoder-wlci';
      if (this.getRouteName().includes('products-whole-life-protection')) return 'decoder-whole-life';
      if (this.getRouteName().includes('products-home')) return 'decoder-home';
      if (this.getRouteName().includes('products-travel')) return 'decoder-travel';
      if (this.getRouteName().includes('products-personal-accident')) return 'decoder-pa';
      if (/^(products-).+(-slug)$/.test(this.getRouteName())) return 'product-detail';
      if (this.getRouteName() === '5star-products-year') return 'five-star-main';
      if (this.getRouteName() === '5star-products-year-insurer-insurer') return 'five-star-insurer';
      if (this.getRouteName() === '5star-products-year-productCat') return 'five-star-category';
      if (this.getRouteName() === 'my-favourite') return 'my-favourite';
      if (this.getRouteName() === 'media') return 'media-centre';
      if (this.getRouteName() === 'latest-promotion') return 'brand-promotion';
      if (this.getRouteName() === 'scoring-methodology') return 'scoring-methodology';
      if (this.getRouteName() === 'scoring-methodology-slug') return 'scoring-methodology-slug';
      if (this.getRouteName() === 'about-us') return 'about-us';
      if (this.getRouteName().includes('happy-kids')) return 'family-site';
      if (this.getRouteName().includes('hong-kong-insurance')) return 'hong-kong-insurance-zone-section-landing';
      
      if (this.getRouteName() === 'qdap-year') return 'qdap-mall-homepage';
      if (this.getRouteName() === 'qdap-year-products-slug') return 'qdap-mall-products';
      return 'general';
    },
    getEventTrackingLocationName() {
      if (this.getRouteName() === 'index') return 'homepage';
      if (this.getRouteName() === 'life-stage') return 'lifestage';
      if (this.getRouteName() === 'advisor-service') return 'broker';

      const url = location.href;
      const patternDecoderMain = /^.*\/products[/]?$/;
      const patternComparison = /^.*\/products\/([-a-zA-Z\d/]+)[/]compare[/]?[?]*(.*)$/;
      if (patternDecoderMain.test(url)) {
        return 'decoder_main';
      }
      if (patternComparison.test(url)) {
        return 'comparison';
      }

      return 'other';
    },
    isEComLink(link) {
      return link.includes(process.env.SALES_EXTERNAL_URL)
    },
    isTransitionLink(link) {
      return link?.includes('transition-checkout');
    },
    isBlogToDecoderBannerLink(link) {
      return link?.includes('blog-to-decoder-banner=true');
    },
    isBlogToDecoderHyperlinkLink(link) {
      return link?.includes('blog-to-decoder-hyperlink=true');
    },
    isBlogToProductDetailsLink(link) {
      return link?.includes('blog-to-product-details=true');
    },
    isWhatsappLink(link) {
      return link?.includes('whatsapp.com') || link.includes('wa.me');
    },
    isCrossSellingBanner(el) {
      const closestCrossSellingBannerNode = el.closest('.cross-selling-banner');
      if (closestCrossSellingBannerNode) {
        return true;
      }
      return false;
    },
    extractDataFromTransitionLink(link) {
      const data = {
        link: '',
        insurer: '',
        pp_status: '',
        category: '',
        section: null,
      };
      const url = new URL(link);
      const params = new URLSearchParams(url.search);
      data.link = params.get('link');
      data.insurer = params.get('insurer');
      data.pp_status = params.get('pp_status');
      data.category = params.get('category');
      data.section = params.get('section');
      return data;
    },
    extractDataFromProductLink(link) {
      const data = {
        link,
        is_product_link: false,
        category: undefined,
        insurer_code: undefined,
        product_name: undefined,
        scenario_code: undefined,
        query_string: undefined,
        product_id_part: undefined,
      };
      const pattern = /^[./\-:/a-zA-Z\d]*\/products\/([-a-zA-Z\d]+)\/([-+_=%a-zA-Z\d]+)[/]?[?]*([-+_=%&a-zA-Z\d]*)[/]?[?]*([-+_=%&a-zA-Z\d]*)$/;
      const matches = link.match(pattern);
      if (matches) {
        data.is_product_link = true;
        data.product_id_part = link.split('/products/')[1];
        if (matches[1] && matches[1] === 'deferred-annuity') {
          data.category = matches[1] + '-' + matches[2];
          data.scenario_code = matches[3];
          data.query_string = matches[4];
        } else {
          data.category = matches[1];
          data.scenario_code = matches[2];
          data.query_string = matches[3];
        }

        const params = new URLSearchParams(data.query_string);
        data.insurer_code = params.get('insurer');
        data.product_name = params.get('product');
        data.category = params.get('category') ? params.get('category') : data.category;
      }
      return data;
    },
    extractDataFromProductListingLink(link) {
      const data = {
        link,
        is_product_link: false,
        category: undefined,
        insurer_code: undefined,
        product_name: undefined,
        scenario_code: undefined,
        query_string: undefined,
        product_id_part: undefined,
      };
      const pattern = /^.*\/products\/([-a-zA-Z\d]+)[/]?[?]*(.*)/;

      const matches = link.match(pattern);
      if (matches) {
        data.is_product_link = true;
        data.product_id_part = link.split('/products/')[1];
        data.category = matches[1];
        data.query_string = matches[2];

        const params = new URLSearchParams(data.query_string);
        data.insurer_code = params.get('insurer') || undefined;
        data.product_name = params.get('product') || undefined;
        data.category = params.get('category') ? params.get('category') : data.category;
      }
      return data;
    },
    extractDataFromProductComparisonLink(link) {
      const data = {
        link,
        is_product_link: false,
        category: undefined,
        insurer_code: undefined,
        product_name: undefined,
        scenario_code: undefined,
        query_string: undefined,
        product_id_part: undefined,
      };
      const pattern = /^.*\/products\/([-a-zA-Z\d]+)[/]?[?]*(.*)/;

      const matches = link.match(pattern);
      if (matches) {
        data.is_product_link = true;
        data.product_id_part = link.split('/products/')[1];
        data.category = matches[1];
        data.query_string = matches[2];

        const params = new URLSearchParams(data.query_string);
        data.insurer_code = params.get('insurer') || undefined;
        data.product_name = params.get('product') || undefined;
        data.category = params.get('category') ? params.get('category') : data.category;
      }
      return data;
    },
    extractDataFromDecoderLink(link) {
      const data = {
        link,
        is_decoder_link: false,
        category: '',
        query_string: '',
        product_id_part: '',
      };
      const pattern = /^[./\-:/a-zA-Z\d]*\/products\/([-a-zA-Z\d]+)[/]?[?]?([-+_=%&a-zA-Z\d]*)$/;
      const matches = link.match(pattern);
      if (matches) {
        data.is_decoder_link = true;
        data.product_id_part = link.split('/products/')[1];
        data.category = matches[1];
        data.query_string = matches[2];

        const params = new URLSearchParams(data.query_string);
        data.insurer_code = params.get('insurer');
        data.product_name = params.get('product');
        data.category = params.get('category') ? params.get('category') : data.category;
      }
      return data;
    },
    extractDataFromClickedElement(el) {
      const data = {
        link_node: null,
        href: null,
        link_text: null,
        class: null,
        tracking_target: null,
        offsetparent_node: null,
        image_path: null,
        card_title: null,
        card_category: null,
        insurer_name: null,
      };
      {

        const fiveStarProductCardNode = el.closest('.five-star-products-card');
        const fiveStarInsurerPageProductCardNode = el.closest('.five-star-products-insurer-product-card');

        let closestLinkNode = el.closest('a');
        if (!closestLinkNode) {
          closestLinkNode = el.closest('button');
        }
        if (!closestLinkNode) {
          closestLinkNode = el.closest('.fav-action-button');
        }
        data.tracking_target = this.identifyTrackingTarget(closestLinkNode);
        if (closestLinkNode) {
          if (closestLinkNode) data.link_node = closestLinkNode;
          data.offsetparent_node = closestLinkNode.offsetParent;
          if (closestLinkNode?.href) data.href = closestLinkNode?.href;
          if (closestLinkNode?.dataHref) data.href = closestLinkNode?.dataHref;
          if (closestLinkNode) {
            data.link_text = closestLinkNode?.innerText ? closestLinkNode?.innerText : closestLinkNode?.currentSrc;
          }
          if (closestLinkNode) data.class = closestLinkNode?.classList.toString();
        }
        if (el.tagName === 'IMG') {
          data.image_path = el.src;
        }
        const cardTitleNode = fiveStarProductCardNode?.querySelector('.title') || fiveStarInsurerPageProductCardNode?.querySelector('.product-name');
        if (cardTitleNode) {
          data.card_title = cardTitleNode.innerText;
        }
        const cardCategoryNode = fiveStarInsurerPageProductCardNode?.querySelector('[data-category]');
        if (cardCategoryNode) {
          data.card_category = cardCategoryNode.getAttribute('data-category');
        }
        const insurerNameNode = fiveStarProductCardNode?.querySelector('.insurer-name') || fiveStarInsurerPageProductCardNode?.querySelector('.insurer-name');
        if (insurerNameNode) {
          data.insurer_name = insurerNameNode.innerText;
        }
      }
      return data;
    },
    extractDataFromProductCardNode(elNode) {

      const data = {
        current_url: null,
        product: {
          insurer_name: undefined,
          product_name: undefined,
          product_category: undefined,
          scenario_code: undefined,
        },
      };

      let node
      node = elNode.closest(".product-listing-card")
      if (!node) {
        node = elNode.closest(".product-compact-card")
      }

      data.product.insurer_name = node.getAttribute("data-insurer-code")
      data.product.product_name = node.getAttribute("data-product-name")
      data.product.product_category = node.getAttribute("data-product-category")
      data.product.scenario_code = node.getAttribute("data-product-id")

      return data;
    },
    extractDataFromCurrentProductMainPage() {
      const data = {
        current_url: null,
        product: {
          insurer_name: undefined,
          product_name: undefined,
          product_category: undefined,
          scenario_code: undefined,
        },
      };

      const url = location.href;
      const productData = this.extractDataFromProductListingLink(url);
      data.product.insurer_name = productData.insurer_code;
      data.product.product_name = productData.product_name;
      data.product.product_category = productData.category;
      data.product.scenario_code = productData.scenario_code;

      if (!data.product?.insurer_name) {
        const node = this.$root.$el.querySelector('.product-detail-wrapper');
        if (node) {
          const insurer = node.querySelector('[data-insurer-company]');
          data.product.insurer_name = insurer.getAttribute('data-insurer-company');
        }
      }
      if (!data.product?.product_name) {
        const node = this.$root.$el.querySelector('.product-detail-wrapper');
        if (node) {
          const product = node.querySelector('[data-product-name]');
          data.product.product_name = product.getAttribute('data-product-name');
        }
      }
      return data;
    },
    extractDataFromCurrentProductDetailPage() {
      const data = {
        current_url: null,
        product: {
          insurer_name: undefined,
          product_name: undefined,
          product_category: undefined,
          scenario_code: undefined,
        },
      };

      const url = location.href;
      const productData = this.extractDataFromProductLink(url);
      data.product.insurer_name = productData.insurer_code;
      data.product.product_name = productData.product_name;
      data.product.product_category = productData.category;
      data.product.scenario_code = productData.scenario_code;

      if (!data.product?.insurer_name) {
        const node = this.$root.$el.querySelector('.product-detail-wrapper');
        if (node) {
          const insurer = node.querySelector('[data-insurer-company]');
          data.product.insurer_name = insurer.getAttribute('data-insurer-company');
        }
      }
      if (!data.product?.product_name) {
        const node = this.$root.$el.querySelector('.product-detail-wrapper');
        if (node) {
          const product = node.querySelector('[data-product-name]');
          data.product.product_name = product.getAttribute('data-product-name');
        }
      }
      return data;
    },
    extractDataFromCurrentProductComparisonPage() {
      const data = {
        current_url: null,
        product: {
          insurer_name: undefined,
          product_name: undefined,
          product_category: undefined,
          scenario_code: undefined,
        },
      };

      const url = location.href;
      const productData = this.extractDataFromProductComparisonLink(url);
      data.product.insurer_name = productData.insurer_code;
      data.product.product_name = productData.product_name;
      data.product.product_category = productData.category;
      data.product.scenario_code = productData.scenario_code;

      if (!data.product?.insurer_name) {
        const node = this.$root.$el.querySelector('.product-detail-wrapper');
        if (node) {
          const insurer = node.querySelector('[data-insurer-company]');
          data.product.insurer_name = insurer.getAttribute('data-insurer-company');
        }
      }
      if (!data.product?.product_name) {
        const node = this.$root.$el.querySelector('.product-detail-wrapper');
        if (node) {
          const product = node.querySelector('[data-product-name]');
          data.product.product_name = product.getAttribute('data-product-name');
        }
      }
      return data;
    },
    identifyTrackingTarget(nodeTarget) {
      const data = {
        target_code: null,
        page_name: null,
        page_path: null,
      };
      data.page_name = this.$route.name;
      data.page_path = this.$route.path;
      if (nodeTarget.classList.contains('product-card-link')) {
        data.target_code = 'product-card-link';
      }
      if (nodeTarget.classList.contains('product-card-cta-link')) {
        if (nodeTarget.classList.contains('product-website')) {
          data.target_code = 'product-card-cta-link-product-website';
        } else {
          data.target_code = 'product-card-cta-link';
        }
      }
      if (nodeTarget.classList.contains('product-highlight-cta-link')) {
        if (nodeTarget.classList.contains('product-website')) {
          data.target_code = 'product-highlight-cta-link-product-website';
        } else {
          data.target_code = 'product-highlight-cta-link';
        }
      }
      if (nodeTarget.classList.contains('product-promo-more')) {
        data.target_code = 'product-promo-more';
      }
      if (nodeTarget.classList.contains('product-promo-cta')) {
        data.target_code = 'product-promo-cta';
      }
      if (nodeTarget.classList.contains('product-promo-modal-cta')) {
        data.target_code = 'product-promo-modal-cta';
      }
      if (nodeTarget.classList.contains('product-page-content')) {
        data.target_code = 'product-page-content';
      }
      if (nodeTarget.classList.contains('consultation-link')) {
        data.target_code = 'consultation-link';
      }
      if (nodeTarget.classList.contains('product-compare-button')) {
        data.target_code = 'product-compare-button';
      }
      return data;
    },
    getCurrentPageInfo(link) {
      const l = 'aaa'
      const data = {
        page_name: null,
        page_path: null,
        page_type: null,
      };
      data.page_name = this.$route.name;
      data.page_path = this.$route.path;
      const getPageType = (link) => {

        const patternBlogSinge = /[./\-:/a-zA-Z\d]*\/blog\/([-a-zA-Z\d]+)/;
        const patternDecoder = /^[./\-:/a-zA-Z\d]*\/products\/([-a-zA-Z\d]+)(?!.*\/)?[?]?([-+_=%&a-zA-Z\d]*)$/;
        const patternProductDetail = /^[./\-:/a-zA-Z\d]*\/products\/([-a-zA-Z\d]+)\/([-+_=%a-zA-Z\d]+)[/]?[?]*([-+_=%&a-zA-Z\d]*)[/]?[?]*([-+_=%&a-zA-Z\d]*)$/;
        if (link.match(patternDecoder)) {
          return 'decoder'
        }
        if (link.match(patternProductDetail)) {
          return 'productDetail'
        }
        if (link.match(patternBlogSinge)) {
          return 'blogSingle'
        }
        return 'other'
      }
      data.page_type = getPageType(link)
      return data
    },
    handleEventTracking_signUp() {
      const loginModalEntryPoint = this.$store.getters['modal/loginModalEntryPoint']
      const eventTrackingParams = this.initEventTrackingParams()

      if (loginModalEntryPoint === 'header_icon') {
        // #91 
        eventTrackingParams.a = 'register'
        eventTrackingParams.l = null
        eventTrackingParams.c = `header_icon`
        eventTrackingParams.cd.insurer_name = null
        eventTrackingParams.cd.product_name = null
        eventTrackingParams.cd.product_category = null
      }
      if (loginModalEntryPoint === 'my_save_page_popup') {
        // #93 
        eventTrackingParams.a = 'register'
        eventTrackingParams.l = null
        eventTrackingParams.c = `my_save_page_popup`
        eventTrackingParams.cd.insurer_name = null
        eventTrackingParams.cd.product_name = null
        eventTrackingParams.cd.product_category = null
      }

      this.$store.commit('modal/setLoginModalEntryPoint', null);
      this.sendEventTracking(eventTrackingParams)
    }

  },

  watch: {
    componentRendered(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        setTimeout(() => {
          this.setEventTrackingListeners(this.listenerTasks);
        }, 2000)
      }
    },
  }
};