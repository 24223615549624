
import eventTrackingMixins from '~/mixins/eventTrackingMixins.js';

export default {
  name: 'WechatFloatingWidget',
  
  mixins: [eventTrackingMixins],
  
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    openWechat() {
      this.$store.commit('modal/setShowWechatModal', true);
      
      this.handleEventTracking();
    },
    handleEventTracking() {
      const url = location.href;
      const patternDecoder = /^.*\/products\/([-a-zA-Z\d]+)((?!\/compare).)*$/g;
      const patternComparison = /^.*\/products\/([-a-zA-Z\d/]+)[/]compare[/]?[?]*(.*)$/;
      if(patternDecoder.test(url) && /^products-(?!.*-slug).*$/.test(this.getRouteName())) {
        // #155 decoder_widget
        const eventTrackingParams = this.initEventTrackingParams()

        const url = location.href;
        const productData = this.extractDataFromProductListingLink(url)
        const insuranceCompany = productData.insurer_code
        const productScenarioCode = productData.scenario_code
        const productName = productData.product_name
        const productCategory = productData.category
        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  productScenarioCode
        eventTrackingParams.c = `decoder_widget`
        eventTrackingParams.cd.insurer_name = insuranceCompany
        eventTrackingParams.cd.product_name = productName
        eventTrackingParams.cd.product_category = productCategory
      
        this.sendEventTracking(eventTrackingParams)

      } else if(/^(products-).+(-slug)$/.test(this.getRouteName())) {
        // #161 product_details_widget
        const eventTrackingParams = this.initEventTrackingParams()

        const productData = this.extractDataFromCurrentProductDetailPage()
        const insuranceCompany = productData.product.insurer_name
        const productScenarioCode = productData.product.scenario_code
        const productName = productData.product.product_name
        const productCategory = productData.product.product_category
        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  productScenarioCode
        eventTrackingParams.c = `product_details_widget`
        eventTrackingParams.cd.insurer_name = insuranceCompany
        eventTrackingParams.cd.product_name = productName
        eventTrackingParams.cd.product_category = productCategory
      
        this.sendEventTracking(eventTrackingParams)
      } else if(/^(5star-products)(.)*$/.test(this.getRouteName())) {
        // #182 open_wechat 5star_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `5star_widget`
        eventTrackingParams.cd.insurer_name = undefined
        eventTrackingParams.cd.product_name = undefined
        eventTrackingParams.cd.product_category = undefined
      
        this.sendEventTracking(eventTrackingParams)
      } else if(/^(hong-kong-insurance)$/.test(this.getRouteName())) {
        // #171 open_wechat drifter_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `drifter_widget`
        eventTrackingParams.cd.insurer_name = undefined
        eventTrackingParams.cd.product_name = undefined
        eventTrackingParams.cd.product_category = undefined

        this.sendEventTracking(eventTrackingParams)
      } else if(/^(insurer)(.)*$/.test(this.getRouteName())) {
        // #225 open_wechat Insurer_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `Insurer_widget`
        eventTrackingParams.cd.insurer_name = undefined
        eventTrackingParams.cd.product_name = undefined
        eventTrackingParams.cd.product_category = undefined

        this.sendEventTracking(eventTrackingParams)
      }  else if (this.getPlacementCodeByRouteName() === 'five-star-main' || this.getPlacementCodeByRouteName() === 'five-star-insurer' || this.getPlacementCodeByRouteName() === 'five-star-category' ) {
        // #151 5star_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `5star_widget`
      
        this.sendEventTracking(eventTrackingParams)
      } else if (this.getPlacementCodeByRouteName() === 'blog-single' ||  this.getPlacementCodeByRouteName() === 'blog-listing') {
        // #152 blog_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `blog_widget`
      
        this.sendEventTracking(eventTrackingParams)
      } else if (this.getPlacementCodeByRouteName() === 'my-favourite') {
        // #159 my_save_page_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `my_save_page_widget`
      
        this.sendEventTracking(eventTrackingParams)
      } else if (patternComparison.test(url)) {
        // #163 comparison_widget
        const productData = this.extractDataFromCurrentProductComparisonPage()
        const productCategory = productData.product.product_category
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `comparison_widget`
        eventTrackingParams.cd.product_category = productCategory
      
        this.sendEventTracking(eventTrackingParams)
      } else if (this.getPlacementCodeByRouteName() === 'qdap-mall-homepage') {
        // #205 QDAP Mall - Landing Page Whatsapp Widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `qdap_widget`
      
        this.sendEventTracking(eventTrackingParams)
      } else {
        // #153 #154 #157 #158 #160
        const params = {}
        params.categoryPrefix = `${this.getEventTrackingLocationName()}_`

        const eventTrackingParams = this.initEventTrackingParams()
        eventTrackingParams.a = 'open_wechat'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = params.categoryPrefix + 'widget'

        this.sendEventTracking(eventTrackingParams)

      }
    },
  },
};
