export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const FiveStarInsurerSideBySideSection = () => import('../../components/5star-products/FiveStarInsurerSideBySideSection.vue' /* webpackChunkName: "components/five-star-insurer-side-by-side-section" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsAwardInsurerCard = () => import('../../components/5star-products/FiveStarProductsAwardInsurerCard.vue' /* webpackChunkName: "components/five-star-products-award-insurer-card" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsAwardInsurerSection = () => import('../../components/5star-products/FiveStarProductsAwardInsurerSection.vue' /* webpackChunkName: "components/five-star-products-award-insurer-section" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsAwardProducts = () => import('../../components/5star-products/FiveStarProductsAwardProducts.vue' /* webpackChunkName: "components/five-star-products-award-products" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsCard = () => import('../../components/5star-products/FiveStarProductsCard.vue' /* webpackChunkName: "components/five-star-products-card" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsCardLayout = () => import('../../components/5star-products/FiveStarProductsCardLayout.vue' /* webpackChunkName: "components/five-star-products-card-layout" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsCategories = () => import('../../components/5star-products/FiveStarProductsCategories.vue' /* webpackChunkName: "components/five-star-products-categories" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsCategoryNav = () => import('../../components/5star-products/FiveStarProductsCategoryNav.vue' /* webpackChunkName: "components/five-star-products-category-nav" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsFaq = () => import('../../components/5star-products/FiveStarProductsFaq.vue' /* webpackChunkName: "components/five-star-products-faq" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsFilterSection = () => import('../../components/5star-products/FiveStarProductsFilterSection.vue' /* webpackChunkName: "components/five-star-products-filter-section" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsInsurerCardsSection = () => import('../../components/5star-products/FiveStarProductsInsurerCardsSection.vue' /* webpackChunkName: "components/five-star-products-insurer-cards-section" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsInsurerHead = () => import('../../components/5star-products/FiveStarProductsInsurerHead.vue' /* webpackChunkName: "components/five-star-products-insurer-head" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsInsurerHeadSection = () => import('../../components/5star-products/FiveStarProductsInsurerHeadSection.vue' /* webpackChunkName: "components/five-star-products-insurer-head-section" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsInsurerPage = () => import('../../components/5star-products/FiveStarProductsInsurerPage.vue' /* webpackChunkName: "components/five-star-products-insurer-page" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsInsurerProductCard = () => import('../../components/5star-products/FiveStarProductsInsurerProductCard.vue' /* webpackChunkName: "components/five-star-products-insurer-product-card" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsMethodologyAndProductsSection = () => import('../../components/5star-products/FiveStarProductsMethodologyAndProductsSection.vue' /* webpackChunkName: "components/five-star-products-methodology-and-products-section" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsMethodologySection = () => import('../../components/5star-products/FiveStarProductsMethodologySection.vue' /* webpackChunkName: "components/five-star-products-methodology-section" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsSectionBanner = () => import('../../components/5star-products/FiveStarProductsSectionBanner.vue' /* webpackChunkName: "components/five-star-products-section-banner" */).then(c => wrapFunctional(c.default || c))
export const FiveStarProductsSubcategoryOptions = () => import('../../components/5star-products/FiveStarProductsSubcategoryOptions.vue' /* webpackChunkName: "components/five-star-products-subcategory-options" */).then(c => wrapFunctional(c.default || c))
export const FiveStarTitleWithCategoryButton = () => import('../../components/5star-products/FiveStarTitleWithCategoryButton.vue' /* webpackChunkName: "components/five-star-title-with-category-button" */).then(c => wrapFunctional(c.default || c))
export const FiveStarVideo = () => import('../../components/5star-products/FiveStarVideo.vue' /* webpackChunkName: "components/five-star-video" */).then(c => wrapFunctional(c.default || c))
export const AboutUsHeaderSection = () => import('../../components/about-us/AboutUsHeaderSection.vue' /* webpackChunkName: "components/about-us-header-section" */).then(c => wrapFunctional(c.default || c))
export const AboutUsVideoSection = () => import('../../components/about-us/AboutUsVideoSection.vue' /* webpackChunkName: "components/about-us-video-section" */).then(c => wrapFunctional(c.default || c))
export const OurAdvisorsSection = () => import('../../components/about-us/OurAdvisorsSection.vue' /* webpackChunkName: "components/our-advisors-section" */).then(c => wrapFunctional(c.default || c))
export const OurFoundersSection = () => import('../../components/about-us/OurFoundersSection.vue' /* webpackChunkName: "components/our-founders-section" */).then(c => wrapFunctional(c.default || c))
export const OurManagementTeamSection = () => import('../../components/about-us/OurManagementTeamSection.vue' /* webpackChunkName: "components/our-management-team-section" */).then(c => wrapFunctional(c.default || c))
export const OurMilestonesSection = () => import('../../components/about-us/OurMilestonesSection.vue' /* webpackChunkName: "components/our-milestones-section" */).then(c => wrapFunctional(c.default || c))
export const OurTeamSection = () => import('../../components/about-us/OurTeamSection.vue' /* webpackChunkName: "components/our-team-section" */).then(c => wrapFunctional(c.default || c))
export const OurValuesSection = () => import('../../components/about-us/OurValuesSection.vue' /* webpackChunkName: "components/our-values-section" */).then(c => wrapFunctional(c.default || c))
export const AdvisorServiceComparisonSection = () => import('../../components/advisor-service/AdvisorServiceComparisonSection.vue' /* webpackChunkName: "components/advisor-service-comparison-section" */).then(c => wrapFunctional(c.default || c))
export const AdvisorServiceHeaderSection = () => import('../../components/advisor-service/AdvisorServiceHeaderSection.vue' /* webpackChunkName: "components/advisor-service-header-section" */).then(c => wrapFunctional(c.default || c))
export const AdvisorServiceVideo = () => import('../../components/advisor-service/AdvisorServiceVideo.vue' /* webpackChunkName: "components/advisor-service-video" */).then(c => wrapFunctional(c.default || c))
export const AdvisorServiceVideoSection = () => import('../../components/advisor-service/AdvisorServiceVideoSection.vue' /* webpackChunkName: "components/advisor-service-video-section" */).then(c => wrapFunctional(c.default || c))
export const OurCommitmentSection = () => import('../../components/advisor-service/OurCommitmentSection.vue' /* webpackChunkName: "components/our-commitment-section" */).then(c => wrapFunctional(c.default || c))
export const OurServiceSection = () => import('../../components/advisor-service/OurServiceSection.vue' /* webpackChunkName: "components/our-service-section" */).then(c => wrapFunctional(c.default || c))
export const SessionCheckIframe = () => import('../../components/auth/SessionCheckIframe.vue' /* webpackChunkName: "components/session-check-iframe" */).then(c => wrapFunctional(c.default || c))
export const BlogAdvisorDesktopCard = () => import('../../components/blog/BlogAdvisorDesktopCard.vue' /* webpackChunkName: "components/blog-advisor-desktop-card" */).then(c => wrapFunctional(c.default || c))
export const BlogAdvisorMobileCard = () => import('../../components/blog/BlogAdvisorMobileCard.vue' /* webpackChunkName: "components/blog-advisor-mobile-card" */).then(c => wrapFunctional(c.default || c))
export const BlogAuthorBriefSection = () => import('../../components/blog/BlogAuthorBriefSection.vue' /* webpackChunkName: "components/blog-author-brief-section" */).then(c => wrapFunctional(c.default || c))
export const BlogAuthorFilterSection = () => import('../../components/blog/BlogAuthorFilterSection.vue' /* webpackChunkName: "components/blog-author-filter-section" */).then(c => wrapFunctional(c.default || c))
export const BlogAuthorLogoName = () => import('../../components/blog/BlogAuthorLogoName.vue' /* webpackChunkName: "components/blog-author-logo-name" */).then(c => wrapFunctional(c.default || c))
export const BlogAuthorSidebarLayout = () => import('../../components/blog/BlogAuthorSidebarLayout.vue' /* webpackChunkName: "components/blog-author-sidebar-layout" */).then(c => wrapFunctional(c.default || c))
export const BlogCategories = () => import('../../components/blog/BlogCategories.vue' /* webpackChunkName: "components/blog-categories" */).then(c => wrapFunctional(c.default || c))
export const BlogContentAnchorSection = () => import('../../components/blog/BlogContentAnchorSection.vue' /* webpackChunkName: "components/blog-content-anchor-section" */).then(c => wrapFunctional(c.default || c))
export const BlogFilterAuthors = () => import('../../components/blog/BlogFilterAuthors.vue' /* webpackChunkName: "components/blog-filter-authors" */).then(c => wrapFunctional(c.default || c))
export const BlogFilterMobileMenu = () => import('../../components/blog/BlogFilterMobileMenu.vue' /* webpackChunkName: "components/blog-filter-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const BlogFilterSection = () => import('../../components/blog/BlogFilterSection.vue' /* webpackChunkName: "components/blog-filter-section" */).then(c => wrapFunctional(c.default || c))
export const BlogFilterTags = () => import('../../components/blog/BlogFilterTags.vue' /* webpackChunkName: "components/blog-filter-tags" */).then(c => wrapFunctional(c.default || c))
export const BlogFilterTypes = () => import('../../components/blog/BlogFilterTypes.vue' /* webpackChunkName: "components/blog-filter-types" */).then(c => wrapFunctional(c.default || c))
export const BlogGrid = () => import('../../components/blog/BlogGrid.vue' /* webpackChunkName: "components/blog-grid" */).then(c => wrapFunctional(c.default || c))
export const BlogListing = () => import('../../components/blog/BlogListing.vue' /* webpackChunkName: "components/blog-listing" */).then(c => wrapFunctional(c.default || c))
export const BlogRecommendedSection = () => import('../../components/blog/BlogRecommendedSection.vue' /* webpackChunkName: "components/blog-recommended-section" */).then(c => wrapFunctional(c.default || c))
export const BlogRelatedProductCard = () => import('../../components/blog/BlogRelatedProductCard.vue' /* webpackChunkName: "components/blog-related-product-card" */).then(c => wrapFunctional(c.default || c))
export const BlogRelatedProductSection = () => import('../../components/blog/BlogRelatedProductSection.vue' /* webpackChunkName: "components/blog-related-product-section" */).then(c => wrapFunctional(c.default || c))
export const BlogSectionBanner = () => import('../../components/blog/BlogSectionBanner.vue' /* webpackChunkName: "components/blog-section-banner" */).then(c => wrapFunctional(c.default || c))
export const BlogSideWidget = () => import('../../components/blog/BlogSideWidget.vue' /* webpackChunkName: "components/blog-side-widget" */).then(c => wrapFunctional(c.default || c))
export const BlogSidebarLayout = () => import('../../components/blog/BlogSidebarLayout.vue' /* webpackChunkName: "components/blog-sidebar-layout" */).then(c => wrapFunctional(c.default || c))
export const BlogSingle = () => import('../../components/blog/BlogSingle.vue' /* webpackChunkName: "components/blog-single" */).then(c => wrapFunctional(c.default || c))
export const BlogSocialSharingSection = () => import('../../components/blog/BlogSocialSharingSection.vue' /* webpackChunkName: "components/blog-social-sharing-section" */).then(c => wrapFunctional(c.default || c))
export const BlogTrendingSection = () => import('../../components/blog/BlogTrendingSection.vue' /* webpackChunkName: "components/blog-trending-section" */).then(c => wrapFunctional(c.default || c))
export const ToggleFavouriteBlog = () => import('../../components/blog/ToggleFavouriteBlog.vue' /* webpackChunkName: "components/toggle-favourite-blog" */).then(c => wrapFunctional(c.default || c))
export const AdRotationBanner = () => import('../../components/common/AdRotationBanner.vue' /* webpackChunkName: "components/ad-rotation-banner" */).then(c => wrapFunctional(c.default || c))
export const BackToTopButton = () => import('../../components/common/BackToTopButton.vue' /* webpackChunkName: "components/back-to-top-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonComponent = () => import('../../components/common/ButtonComponent.vue' /* webpackChunkName: "components/button-component" */).then(c => wrapFunctional(c.default || c))
export const ButtonWithArrow = () => import('../../components/common/ButtonWithArrow.vue' /* webpackChunkName: "components/button-with-arrow" */).then(c => wrapFunctional(c.default || c))
export const CrossSellingBanner = () => import('../../components/common/CrossSellingBanner.vue' /* webpackChunkName: "components/cross-selling-banner" */).then(c => wrapFunctional(c.default || c))
export const EnquiryFloatingWidget = () => import('../../components/common/EnquiryFloatingWidget.vue' /* webpackChunkName: "components/enquiry-floating-widget" */).then(c => wrapFunctional(c.default || c))
export const Faq = () => import('../../components/common/Faq.vue' /* webpackChunkName: "components/faq" */).then(c => wrapFunctional(c.default || c))
export const FunctionBar = () => import('../../components/common/FunctionBar.vue' /* webpackChunkName: "components/function-bar" */).then(c => wrapFunctional(c.default || c))
export const ImageComponent = () => import('../../components/common/ImageComponent.vue' /* webpackChunkName: "components/image-component" */).then(c => wrapFunctional(c.default || c))
export const ImgComponent = () => import('../../components/common/ImgComponent.vue' /* webpackChunkName: "components/img-component" */).then(c => wrapFunctional(c.default || c))
export const PageBackgroundCircle = () => import('../../components/common/PageBackgroundCircle.vue' /* webpackChunkName: "components/page-background-circle" */).then(c => wrapFunctional(c.default || c))
export const PageDisclaimer = () => import('../../components/common/PageDisclaimer.vue' /* webpackChunkName: "components/page-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const PromoFloatingWidget = () => import('../../components/common/PromoFloatingWidget.vue' /* webpackChunkName: "components/promo-floating-widget" */).then(c => wrapFunctional(c.default || c))
export const ReusableButton = () => import('../../components/common/ReusableButton.vue' /* webpackChunkName: "components/reusable-button" */).then(c => wrapFunctional(c.default || c))
export const ReusableDropdown = () => import('../../components/common/ReusableDropdown.vue' /* webpackChunkName: "components/reusable-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ReusableLink = () => import('../../components/common/ReusableLink.vue' /* webpackChunkName: "components/reusable-link" */).then(c => wrapFunctional(c.default || c))
export const ReusableLoading = () => import('../../components/common/ReusableLoading.vue' /* webpackChunkName: "components/reusable-loading" */).then(c => wrapFunctional(c.default || c))
export const ReusablePagination = () => import('../../components/common/ReusablePagination.vue' /* webpackChunkName: "components/reusable-pagination" */).then(c => wrapFunctional(c.default || c))
export const ReusableToolTip = () => import('../../components/common/ReusableToolTip.vue' /* webpackChunkName: "components/reusable-tool-tip" */).then(c => wrapFunctional(c.default || c))
export const RoundCornerCard = () => import('../../components/common/RoundCornerCard.vue' /* webpackChunkName: "components/round-corner-card" */).then(c => wrapFunctional(c.default || c))
export const TenlifeButton = () => import('../../components/common/TenlifeButton.vue' /* webpackChunkName: "components/tenlife-button" */).then(c => wrapFunctional(c.default || c))
export const TextImageTwoColumns = () => import('../../components/common/TextImageTwoColumns.vue' /* webpackChunkName: "components/text-image-two-columns" */).then(c => wrapFunctional(c.default || c))
export const TitleDescription = () => import('../../components/common/TitleDescription.vue' /* webpackChunkName: "components/title-description" */).then(c => wrapFunctional(c.default || c))
export const VideoComponent = () => import('../../components/common/VideoComponent.vue' /* webpackChunkName: "components/video-component" */).then(c => wrapFunctional(c.default || c))
export const WechatButton = () => import('../../components/common/WechatButton.vue' /* webpackChunkName: "components/wechat-button" */).then(c => wrapFunctional(c.default || c))
export const WechatFloatingWidget = () => import('../../components/common/WechatFloatingWidget.vue' /* webpackChunkName: "components/wechat-floating-widget" */).then(c => wrapFunctional(c.default || c))
export const WhatsappFloatingWidget = () => import('../../components/common/WhatsappFloatingWidget.vue' /* webpackChunkName: "components/whatsapp-floating-widget" */).then(c => wrapFunctional(c.default || c))
export const WidgetContainer = () => import('../../components/common/WidgetContainer.vue' /* webpackChunkName: "components/widget-container" */).then(c => wrapFunctional(c.default || c))
export const WidgetContainerMobile = () => import('../../components/common/WidgetContainerMobile.vue' /* webpackChunkName: "components/widget-container-mobile" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/common/breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const ContactUsFormLHSVisualContent = () => import('../../components/contact-us-form/ContactUsFormLHSVisualContent.vue' /* webpackChunkName: "components/contact-us-form-l-h-s-visual-content" */).then(c => wrapFunctional(c.default || c))
export const ContactUsFormRHSForm = () => import('../../components/contact-us-form/ContactUsFormRHSForm.vue' /* webpackChunkName: "components/contact-us-form-r-h-s-form" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneAnchorBar = () => import('../../components/drifter-zone/DrifterZoneAnchorBar.vue' /* webpackChunkName: "components/drifter-zone-anchor-bar" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneBlogSection = () => import('../../components/drifter-zone/DrifterZoneBlogSection.vue' /* webpackChunkName: "components/drifter-zone-blog-section" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneCarouselCard = () => import('../../components/drifter-zone/DrifterZoneCarouselCard.vue' /* webpackChunkName: "components/drifter-zone-carousel-card" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneCarouselSection = () => import('../../components/drifter-zone/DrifterZoneCarouselSection.vue' /* webpackChunkName: "components/drifter-zone-carousel-section" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneDesktopVideosList = () => import('../../components/drifter-zone/DrifterZoneDesktopVideosList.vue' /* webpackChunkName: "components/drifter-zone-desktop-videos-list" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneInternalAnchor = () => import('../../components/drifter-zone/DrifterZoneInternalAnchor.vue' /* webpackChunkName: "components/drifter-zone-internal-anchor" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneProductCard = () => import('../../components/drifter-zone/DrifterZoneProductCard.vue' /* webpackChunkName: "components/drifter-zone-product-card" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneProductCardMobile = () => import('../../components/drifter-zone/DrifterZoneProductCardMobile.vue' /* webpackChunkName: "components/drifter-zone-product-card-mobile" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneProductSection = () => import('../../components/drifter-zone/DrifterZoneProductSection.vue' /* webpackChunkName: "components/drifter-zone-product-section" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneQRCodeWidget = () => import('../../components/drifter-zone/DrifterZoneQRCodeWidget.vue' /* webpackChunkName: "components/drifter-zone-q-r-code-widget" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneSectionBanner = () => import('../../components/drifter-zone/DrifterZoneSectionBanner.vue' /* webpackChunkName: "components/drifter-zone-section-banner" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneSideWidget = () => import('../../components/drifter-zone/DrifterZoneSideWidget.vue' /* webpackChunkName: "components/drifter-zone-side-widget" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneVideoCard = () => import('../../components/drifter-zone/DrifterZoneVideoCard.vue' /* webpackChunkName: "components/drifter-zone-video-card" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneVideoComponent = () => import('../../components/drifter-zone/DrifterZoneVideoComponent.vue' /* webpackChunkName: "components/drifter-zone-video-component" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneVideoSection = () => import('../../components/drifter-zone/DrifterZoneVideoSection.vue' /* webpackChunkName: "components/drifter-zone-video-section" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneWechatSection = () => import('../../components/drifter-zone/DrifterZoneWechatSection.vue' /* webpackChunkName: "components/drifter-zone-wechat-section" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneMobileVideoList = () => import('../../components/drifter-zone/drifterZoneMobileVideoList.vue' /* webpackChunkName: "components/drifter-zone-mobile-video-list" */).then(c => wrapFunctional(c.default || c))
export const FaqPage = () => import('../../components/faq-page/FaqPage.vue' /* webpackChunkName: "components/faq-page" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsBlogCard = () => import('../../components/happy-kids/HappyKidsBlogCard.vue' /* webpackChunkName: "components/happy-kids-blog-card" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsFeaturedCard = () => import('../../components/happy-kids/HappyKidsFeaturedCard.vue' /* webpackChunkName: "components/happy-kids-featured-card" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsFeaturedSection = () => import('../../components/happy-kids/HappyKidsFeaturedSection.vue' /* webpackChunkName: "components/happy-kids-featured-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsHeroMobileSection = () => import('../../components/happy-kids/HappyKidsHeroMobileSection.vue' /* webpackChunkName: "components/happy-kids-hero-mobile-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsHeroSection = () => import('../../components/happy-kids/HappyKidsHeroSection.vue' /* webpackChunkName: "components/happy-kids-hero-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsHomeBlogSection = () => import('../../components/happy-kids/HappyKidsHomeBlogSection.vue' /* webpackChunkName: "components/happy-kids-home-blog-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsHomePageMobileProductCard = () => import('../../components/happy-kids/HappyKidsHomePageMobileProductCard.vue' /* webpackChunkName: "components/happy-kids-home-page-mobile-product-card" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsHomePageProductCard = () => import('../../components/happy-kids/HappyKidsHomePageProductCard.vue' /* webpackChunkName: "components/happy-kids-home-page-product-card" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsHomePageProductSection = () => import('../../components/happy-kids/HappyKidsHomePageProductSection.vue' /* webpackChunkName: "components/happy-kids-home-page-product-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsHomepageSectionBanner = () => import('../../components/happy-kids/HappyKidsHomepageSectionBanner.vue' /* webpackChunkName: "components/happy-kids-homepage-section-banner" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsHomepageVideoSection = () => import('../../components/happy-kids/HappyKidsHomepageVideoSection.vue' /* webpackChunkName: "components/happy-kids-homepage-video-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsInsurerHeroSection = () => import('../../components/happy-kids/HappyKidsInsurerHeroSection.vue' /* webpackChunkName: "components/happy-kids-insurer-hero-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsMobileProductCard = () => import('../../components/happy-kids/HappyKidsMobileProductCard.vue' /* webpackChunkName: "components/happy-kids-mobile-product-card" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsMobileQuickGuide = () => import('../../components/happy-kids/HappyKidsMobileQuickGuide.vue' /* webpackChunkName: "components/happy-kids-mobile-quick-guide" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsMobileSubcategory = () => import('../../components/happy-kids/HappyKidsMobileSubcategory.vue' /* webpackChunkName: "components/happy-kids-mobile-subcategory" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsProductCard = () => import('../../components/happy-kids/HappyKidsProductCard.vue' /* webpackChunkName: "components/happy-kids-product-card" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsQuickGuide = () => import('../../components/happy-kids/HappyKidsQuickGuide.vue' /* webpackChunkName: "components/happy-kids-quick-guide" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsStageSection = () => import('../../components/happy-kids/HappyKidsStageSection.vue' /* webpackChunkName: "components/happy-kids-stage-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsSubcategory = () => import('../../components/happy-kids/HappyKidsSubcategory.vue' /* webpackChunkName: "components/happy-kids-subcategory" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsSubcategoryButton = () => import('../../components/happy-kids/HappyKidsSubcategoryButton.vue' /* webpackChunkName: "components/happy-kids-subcategory-button" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsSubcategoryOptions = () => import('../../components/happy-kids/HappyKidsSubcategoryOptions.vue' /* webpackChunkName: "components/happy-kids-subcategory-options" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsTutorialBlogCard = () => import('../../components/happy-kids/HappyKidsTutorialBlogCard.vue' /* webpackChunkName: "components/happy-kids-tutorial-blog-card" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsTutorialBlogCardMobile = () => import('../../components/happy-kids/HappyKidsTutorialBlogCardMobile.vue' /* webpackChunkName: "components/happy-kids-tutorial-blog-card-mobile" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsTutorialBlogSection = () => import('../../components/happy-kids/HappyKidsTutorialBlogSection.vue' /* webpackChunkName: "components/happy-kids-tutorial-blog-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsTutorialBlogSectionMobile = () => import('../../components/happy-kids/HappyKidsTutorialBlogSectionMobile.vue' /* webpackChunkName: "components/happy-kids-tutorial-blog-section-mobile" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsTutorialVideoCard = () => import('../../components/happy-kids/HappyKidsTutorialVideoCard.vue' /* webpackChunkName: "components/happy-kids-tutorial-video-card" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsTutorialVideoSection = () => import('../../components/happy-kids/HappyKidsTutorialVideoSection.vue' /* webpackChunkName: "components/happy-kids-tutorial-video-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsTutorialVideoSectionMobile = () => import('../../components/happy-kids/HappyKidsTutorialVideoSectionMobile.vue' /* webpackChunkName: "components/happy-kids-tutorial-video-section-mobile" */).then(c => wrapFunctional(c.default || c))
export const HomepageFaq = () => import('../../components/homepage/HomepageFaq.vue' /* webpackChunkName: "components/homepage-faq" */).then(c => wrapFunctional(c.default || c))
export const HomepageHeroBanner = () => import('../../components/homepage/HomepageHeroBanner.vue' /* webpackChunkName: "components/homepage-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const HomepageInsurerPartner = () => import('../../components/homepage/HomepageInsurerPartner.vue' /* webpackChunkName: "components/homepage-insurer-partner" */).then(c => wrapFunctional(c.default || c))
export const HomepageMediaRecognition = () => import('../../components/homepage/HomepageMediaRecognition.vue' /* webpackChunkName: "components/homepage-media-recognition" */).then(c => wrapFunctional(c.default || c))
export const HomepageMilestone = () => import('../../components/homepage/HomepageMilestone.vue' /* webpackChunkName: "components/homepage-milestone" */).then(c => wrapFunctional(c.default || c))
export const HomepageNeedhelp = () => import('../../components/homepage/HomepageNeedhelp.vue' /* webpackChunkName: "components/homepage-needhelp" */).then(c => wrapFunctional(c.default || c))
export const HomepageOffers = () => import('../../components/homepage/HomepageOffers.vue' /* webpackChunkName: "components/homepage-offers" */).then(c => wrapFunctional(c.default || c))
export const HomepageTagging = () => import('../../components/homepage/HomepageTagging.vue' /* webpackChunkName: "components/homepage-tagging" */).then(c => wrapFunctional(c.default || c))
export const HomepageUniqueness = () => import('../../components/homepage/HomepageUniqueness.vue' /* webpackChunkName: "components/homepage-uniqueness" */).then(c => wrapFunctional(c.default || c))
export const HomepageVideoSection = () => import('../../components/homepage/HomepageVideoSection.vue' /* webpackChunkName: "components/homepage-video-section" */).then(c => wrapFunctional(c.default || c))
export const InsurerPageCategoryDetails = () => import('../../components/insurer-page/InsurerPageCategoryDetails.vue' /* webpackChunkName: "components/insurer-page-category-details" */).then(c => wrapFunctional(c.default || c))
export const InsurerPageFaq = () => import('../../components/insurer-page/InsurerPageFaq.vue' /* webpackChunkName: "components/insurer-page-faq" */).then(c => wrapFunctional(c.default || c))
export const InsurerPageFeatureCard = () => import('../../components/insurer-page/InsurerPageFeatureCard.vue' /* webpackChunkName: "components/insurer-page-feature-card" */).then(c => wrapFunctional(c.default || c))
export const InsurerPageRelatedProductSection = () => import('../../components/insurer-page/InsurerPageRelatedProductSection.vue' /* webpackChunkName: "components/insurer-page-related-product-section" */).then(c => wrapFunctional(c.default || c))
export const InsurerPageSectionBanner = () => import('../../components/insurer-page/InsurerPageSectionBanner.vue' /* webpackChunkName: "components/insurer-page-section-banner" */).then(c => wrapFunctional(c.default || c))
export const InsurerPageSubcategoryButton = () => import('../../components/insurer-page/InsurerPageSubcategoryButton.vue' /* webpackChunkName: "components/insurer-page-subcategory-button" */).then(c => wrapFunctional(c.default || c))
export const LeadFormLHSVisualContent = () => import('../../components/lead-form/LeadFormLHSVisualContent.vue' /* webpackChunkName: "components/lead-form-l-h-s-visual-content" */).then(c => wrapFunctional(c.default || c))
export const LeadFormRHSComplete = () => import('../../components/lead-form/LeadFormRHSComplete.vue' /* webpackChunkName: "components/lead-form-r-h-s-complete" */).then(c => wrapFunctional(c.default || c))
export const LeadFormRHSForm = () => import('../../components/lead-form/LeadFormRHSForm.vue' /* webpackChunkName: "components/lead-form-r-h-s-form" */).then(c => wrapFunctional(c.default || c))
export const ComboSection = () => import('../../components/lifestage/ComboSection.vue' /* webpackChunkName: "components/combo-section" */).then(c => wrapFunctional(c.default || c))
export const ImageTextUpDown = () => import('../../components/lifestage/ImageTextUpDown.vue' /* webpackChunkName: "components/image-text-up-down" */).then(c => wrapFunctional(c.default || c))
export const LifeStageDisclaimer = () => import('../../components/lifestage/LifeStageDisclaimer.vue' /* webpackChunkName: "components/life-stage-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const MythsSection = () => import('../../components/lifestage/MythsSection.vue' /* webpackChunkName: "components/myths-section" */).then(c => wrapFunctional(c.default || c))
export const SectionBanner = () => import('../../components/lifestage/SectionBanner.vue' /* webpackChunkName: "components/section-banner" */).then(c => wrapFunctional(c.default || c))
export const SuggestionCard = () => import('../../components/lifestage/SuggestionCard.vue' /* webpackChunkName: "components/suggestion-card" */).then(c => wrapFunctional(c.default || c))
export const SuggestionCardProductCategory = () => import('../../components/lifestage/SuggestionCardProductCategory.vue' /* webpackChunkName: "components/suggestion-card-product-category" */).then(c => wrapFunctional(c.default || c))
export const SuggestionCardSectionHead = () => import('../../components/lifestage/SuggestionCardSectionHead.vue' /* webpackChunkName: "components/suggestion-card-section-head" */).then(c => wrapFunctional(c.default || c))
export const TipsSection = () => import('../../components/lifestage/TipsSection.vue' /* webpackChunkName: "components/tips-section" */).then(c => wrapFunctional(c.default || c))
export const MediaFilterTags = () => import('../../components/media/MediaFilterTags.vue' /* webpackChunkName: "components/media-filter-tags" */).then(c => wrapFunctional(c.default || c))
export const MediaFilterTagsMobile = () => import('../../components/media/MediaFilterTagsMobile.vue' /* webpackChunkName: "components/media-filter-tags-mobile" */).then(c => wrapFunctional(c.default || c))
export const MediaYearFilterTags = () => import('../../components/media/MediaYearFilterTags.vue' /* webpackChunkName: "components/media-year-filter-tags" */).then(c => wrapFunctional(c.default || c))
export const PressReleaseGallery = () => import('../../components/media/PressReleaseGallery.vue' /* webpackChunkName: "components/press-release-gallery" */).then(c => wrapFunctional(c.default || c))
export const PressReleaseGalleryCard = () => import('../../components/media/PressReleaseGalleryCard.vue' /* webpackChunkName: "components/press-release-gallery-card" */).then(c => wrapFunctional(c.default || c))
export const ConfirmationModal = () => import('../../components/modal/ConfirmationModal.vue' /* webpackChunkName: "components/confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const ContactUsFormModal = () => import('../../components/modal/ContactUsFormModal.vue' /* webpackChunkName: "components/contact-us-form-modal" */).then(c => wrapFunctional(c.default || c))
export const DecoderCTAModal = () => import('../../components/modal/DecoderCTAModal.vue' /* webpackChunkName: "components/decoder-c-t-a-modal" */).then(c => wrapFunctional(c.default || c))
export const DisclaimerModal = () => import('../../components/modal/DisclaimerModal.vue' /* webpackChunkName: "components/disclaimer-modal" */).then(c => wrapFunctional(c.default || c))
export const DrifterZoneVideoModal = () => import('../../components/modal/DrifterZoneVideoModal.vue' /* webpackChunkName: "components/drifter-zone-video-modal" */).then(c => wrapFunctional(c.default || c))
export const FilterModalMobile = () => import('../../components/modal/FilterModalMobile.vue' /* webpackChunkName: "components/filter-modal-mobile" */).then(c => wrapFunctional(c.default || c))
export const LeadFormModal = () => import('../../components/modal/LeadFormModal.vue' /* webpackChunkName: "components/lead-form-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsContainer = () => import('../../components/modal/ModalsContainer.vue' /* webpackChunkName: "components/modals-container" */).then(c => wrapFunctional(c.default || c))
export const PopupBannerModal = () => import('../../components/modal/PopupBannerModal.vue' /* webpackChunkName: "components/popup-banner-modal" */).then(c => wrapFunctional(c.default || c))
export const ProfileMenuModal = () => import('../../components/modal/ProfileMenuModal.vue' /* webpackChunkName: "components/profile-menu-modal" */).then(c => wrapFunctional(c.default || c))
export const VideoModal = () => import('../../components/modal/VideoModal.vue' /* webpackChunkName: "components/video-modal" */).then(c => wrapFunctional(c.default || c))
export const WechatModal = () => import('../../components/modal/WechatModal.vue' /* webpackChunkName: "components/wechat-modal" */).then(c => wrapFunctional(c.default || c))
export const FavBlogListing = () => import('../../components/my-favourite/FavBlogListing.vue' /* webpackChunkName: "components/fav-blog-listing" */).then(c => wrapFunctional(c.default || c))
export const FavProductCard = () => import('../../components/my-favourite/FavProductCard.vue' /* webpackChunkName: "components/fav-product-card" */).then(c => wrapFunctional(c.default || c))
export const FavProductListing = () => import('../../components/my-favourite/FavProductListing.vue' /* webpackChunkName: "components/fav-product-listing" */).then(c => wrapFunctional(c.default || c))
export const FunctionBlock = () => import('../../components/navigation-bar/FunctionBlock.vue' /* webpackChunkName: "components/function-block" */).then(c => wrapFunctional(c.default || c))
export const NavigationBar = () => import('../../components/navigation-bar/NavigationBar.vue' /* webpackChunkName: "components/navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const NavigationBarMobile = () => import('../../components/navigation-bar/NavigationBarMobile.vue' /* webpackChunkName: "components/navigation-bar-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProfileMenu = () => import('../../components/navigation-bar/ProfileMenu.vue' /* webpackChunkName: "components/profile-menu" */).then(c => wrapFunctional(c.default || c))
export const ProductDisclaimer = () => import('../../components/products/ProductDisclaimer.vue' /* webpackChunkName: "components/product-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const ProductPromotionDetail = () => import('../../components/products/ProductPromotionDetail.vue' /* webpackChunkName: "components/product-promotion-detail" */).then(c => wrapFunctional(c.default || c))
export const ProductsLandingCard = () => import('../../components/products-landing/ProductsLandingCard.vue' /* webpackChunkName: "components/products-landing-card" */).then(c => wrapFunctional(c.default || c))
export const ProductsLandingCategory = () => import('../../components/products-landing/ProductsLandingCategory.vue' /* webpackChunkName: "components/products-landing-category" */).then(c => wrapFunctional(c.default || c))
export const ProductsLandingContent = () => import('../../components/products-landing/ProductsLandingContent.vue' /* webpackChunkName: "components/products-landing-content" */).then(c => wrapFunctional(c.default || c))
export const ProductsLandingSectionBanner = () => import('../../components/products-landing/ProductsLandingSectionBanner.vue' /* webpackChunkName: "components/products-landing-section-banner" */).then(c => wrapFunctional(c.default || c))
export const QdapHome = () => import('../../components/qdap/qdap-home.vue' /* webpackChunkName: "components/qdap-home" */).then(c => wrapFunctional(c.default || c))
export const QdapProduct = () => import('../../components/qdap/qdap-product.vue' /* webpackChunkName: "components/qdap-product" */).then(c => wrapFunctional(c.default || c))
export const QuickGuideWrapper = () => import('../../components/quick-guide/QuickGuideWrapper.vue' /* webpackChunkName: "components/quick-guide-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyCard = () => import('../../components/scoring-methodology/ScoringMethodologyCard.vue' /* webpackChunkName: "components/scoring-methodology-card" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyCategory = () => import('../../components/scoring-methodology/ScoringMethodologyCategory.vue' /* webpackChunkName: "components/scoring-methodology-category" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyCategoryContent = () => import('../../components/scoring-methodology/ScoringMethodologyCategoryContent.vue' /* webpackChunkName: "components/scoring-methodology-category-content" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyCategorySectionBanner = () => import('../../components/scoring-methodology/ScoringMethodologyCategorySectionBanner.vue' /* webpackChunkName: "components/scoring-methodology-category-section-banner" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyCrossSellingBanner = () => import('../../components/scoring-methodology/ScoringMethodologyCrossSellingBanner.vue' /* webpackChunkName: "components/scoring-methodology-cross-selling-banner" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyFaq = () => import('../../components/scoring-methodology/ScoringMethodologyFaq.vue' /* webpackChunkName: "components/scoring-methodology-faq" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyFullWidthBannerSection = () => import('../../components/scoring-methodology/ScoringMethodologyFullWidthBannerSection.vue' /* webpackChunkName: "components/scoring-methodology-full-width-banner-section" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyGlossaryItem = () => import('../../components/scoring-methodology/ScoringMethodologyGlossaryItem.vue' /* webpackChunkName: "components/scoring-methodology-glossary-item" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyGlossarySection = () => import('../../components/scoring-methodology/ScoringMethodologyGlossarySection.vue' /* webpackChunkName: "components/scoring-methodology-glossary-section" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyMediaSection = () => import('../../components/scoring-methodology/ScoringMethodologyMediaSection.vue' /* webpackChunkName: "components/scoring-methodology-media-section" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologySectionBanner = () => import('../../components/scoring-methodology/ScoringMethodologySectionBanner.vue' /* webpackChunkName: "components/scoring-methodology-section-banner" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyTextModuleSection = () => import('../../components/scoring-methodology/ScoringMethodologyTextModuleSection.vue' /* webpackChunkName: "components/scoring-methodology-text-module-section" */).then(c => wrapFunctional(c.default || c))
export const ScoringMethodologyVideo = () => import('../../components/scoring-methodology/ScoringMethodologyVideo.vue' /* webpackChunkName: "components/scoring-methodology-video" */).then(c => wrapFunctional(c.default || c))
export const TutorialButton = () => import('../../components/tutorial/TutorialButton.vue' /* webpackChunkName: "components/tutorial-button" */).then(c => wrapFunctional(c.default || c))
export const TutorialComparisonSection = () => import('../../components/tutorial/TutorialComparisonSection.vue' /* webpackChunkName: "components/tutorial-comparison-section" */).then(c => wrapFunctional(c.default || c))
export const TutorialContentAnchorSection = () => import('../../components/tutorial/TutorialContentAnchorSection.vue' /* webpackChunkName: "components/tutorial-content-anchor-section" */).then(c => wrapFunctional(c.default || c))
export const TutorialCrossSellingBannerSection = () => import('../../components/tutorial/TutorialCrossSellingBannerSection.vue' /* webpackChunkName: "components/tutorial-cross-selling-banner-section" */).then(c => wrapFunctional(c.default || c))
export const TutorialFaq = () => import('../../components/tutorial/TutorialFaq.vue' /* webpackChunkName: "components/tutorial-faq" */).then(c => wrapFunctional(c.default || c))
export const TutorialFullWidthBannerSection = () => import('../../components/tutorial/TutorialFullWidthBannerSection.vue' /* webpackChunkName: "components/tutorial-full-width-banner-section" */).then(c => wrapFunctional(c.default || c))
export const TutorialGlossaryItem = () => import('../../components/tutorial/TutorialGlossaryItem.vue' /* webpackChunkName: "components/tutorial-glossary-item" */).then(c => wrapFunctional(c.default || c))
export const TutorialGlossarySection = () => import('../../components/tutorial/TutorialGlossarySection.vue' /* webpackChunkName: "components/tutorial-glossary-section" */).then(c => wrapFunctional(c.default || c))
export const TutorialImageTextVertical = () => import('../../components/tutorial/TutorialImageTextVertical.vue' /* webpackChunkName: "components/tutorial-image-text-vertical" */).then(c => wrapFunctional(c.default || c))
export const TutorialSectionBanner = () => import('../../components/tutorial/TutorialSectionBanner.vue' /* webpackChunkName: "components/tutorial-section-banner" */).then(c => wrapFunctional(c.default || c))
export const TutorialSegmentationSection = () => import('../../components/tutorial/TutorialSegmentationSection.vue' /* webpackChunkName: "components/tutorial-segmentation-section" */).then(c => wrapFunctional(c.default || c))
export const TutorialSuggestedBlogGrid = () => import('../../components/tutorial/TutorialSuggestedBlogGrid.vue' /* webpackChunkName: "components/tutorial-suggested-blog-grid" */).then(c => wrapFunctional(c.default || c))
export const TutorialSuggestedBlogSection = () => import('../../components/tutorial/TutorialSuggestedBlogSection.vue' /* webpackChunkName: "components/tutorial-suggested-blog-section" */).then(c => wrapFunctional(c.default || c))
export const TutorialTitle = () => import('../../components/tutorial/TutorialTitle.vue' /* webpackChunkName: "components/tutorial-title" */).then(c => wrapFunctional(c.default || c))
export const BackgroundWithCircle = () => import('../../components/common/SectionBanner/BackgroundWithCircle.vue' /* webpackChunkName: "components/background-with-circle" */).then(c => wrapFunctional(c.default || c))
export const PageSectionBanner = () => import('../../components/common/SectionBanner/PageSectionBanner.vue' /* webpackChunkName: "components/page-section-banner" */).then(c => wrapFunctional(c.default || c))
export const TitleWithSmallCircle = () => import('../../components/common/SectionBanner/TitleWithSmallCircle.vue' /* webpackChunkName: "components/title-with-small-circle" */).then(c => wrapFunctional(c.default || c))
export const ReusableError = () => import('../../components/common/error/ReusableError.vue' /* webpackChunkName: "components/reusable-error" */).then(c => wrapFunctional(c.default || c))
export const ProductAnnuityCardAdapter = () => import('../../components/common/product/ProductAnnuityCardAdapter.vue' /* webpackChunkName: "components/product-annuity-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductHomeCardAdapter = () => import('../../components/common/product/ProductHomeCardAdapter.vue' /* webpackChunkName: "components/product-home-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductMedicalCardAdapter = () => import('../../components/common/product/ProductMedicalCardAdapter.vue' /* webpackChunkName: "components/product-medical-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductPersonalAccidentCardAdapter = () => import('../../components/common/product/ProductPersonalAccidentCardAdapter.vue' /* webpackChunkName: "components/product-personal-accident-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductSavingCardAdapter = () => import('../../components/common/product/ProductSavingCardAdapter.vue' /* webpackChunkName: "components/product-saving-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductShortTermSavingCardAdapter = () => import('../../components/common/product/ProductShortTermSavingCardAdapter.vue' /* webpackChunkName: "components/product-short-term-saving-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductTermCICardAdapter = () => import('../../components/common/product/ProductTermCICardAdapter.vue' /* webpackChunkName: "components/product-term-c-i-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductTermCancerCardAdapter = () => import('../../components/common/product/ProductTermCancerCardAdapter.vue' /* webpackChunkName: "components/product-term-cancer-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductTermLifeCardAdapter = () => import('../../components/common/product/ProductTermLifeCardAdapter.vue' /* webpackChunkName: "components/product-term-life-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductTravelCardAdapter = () => import('../../components/common/product/ProductTravelCardAdapter.vue' /* webpackChunkName: "components/product-travel-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductWLCICardAdapter = () => import('../../components/common/product/ProductWLCICardAdapter.vue' /* webpackChunkName: "components/product-w-l-c-i-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const ProductWLProtectionCardAdapter = () => import('../../components/common/product/ProductWLProtectionCardAdapter.vue' /* webpackChunkName: "components/product-w-l-protection-card-adapter" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsInsurerCardsSection = () => import('../../components/happy-kids/insurer/HappyKidsInsurerCardsSection.vue' /* webpackChunkName: "components/happy-kids-insurer-cards-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsInsurerDetail = () => import('../../components/happy-kids/insurer/HappyKidsInsurerDetail.vue' /* webpackChunkName: "components/happy-kids-insurer-detail" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsInsurerDetailSectionBanner = () => import('../../components/happy-kids/insurer/HappyKidsInsurerDetailSectionBanner.vue' /* webpackChunkName: "components/happy-kids-insurer-detail-section-banner" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsInsurerProductCard = () => import('../../components/happy-kids/insurer/HappyKidsInsurerProductCard.vue' /* webpackChunkName: "components/happy-kids-insurer-product-card" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsInsurerProductCardMobile = () => import('../../components/happy-kids/insurer/HappyKidsInsurerProductCardMobile.vue' /* webpackChunkName: "components/happy-kids-insurer-product-card-mobile" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsInsurerSideBySideSection = () => import('../../components/happy-kids/insurer/HappyKidsInsurerSideBySideSection.vue' /* webpackChunkName: "components/happy-kids-insurer-side-by-side-section" */).then(c => wrapFunctional(c.default || c))
export const HappyKidsInsurerTextSection = () => import('../../components/happy-kids/insurer/HappyKidsInsurerTextSection.vue' /* webpackChunkName: "components/happy-kids-insurer-text-section" */).then(c => wrapFunctional(c.default || c))
export const ModalWrapper = () => import('../../components/modal/common/ModalWrapper.vue' /* webpackChunkName: "components/modal-wrapper" */).then(c => wrapFunctional(c.default || c))
export const NavigationContentContainer = () => import('../../components/navigation-bar/navigation-content/NavigationContentContainer.vue' /* webpackChunkName: "components/navigation-content-container" */).then(c => wrapFunctional(c.default || c))
export const NavigationContentMobileContainer = () => import('../../components/navigation-bar/navigation-content/NavigationContentMobileContainer.vue' /* webpackChunkName: "components/navigation-content-mobile-container" */).then(c => wrapFunctional(c.default || c))
export const ComparisonButton = () => import('../../components/products/comparison/ComparisonButton.vue' /* webpackChunkName: "components/comparison-button" */).then(c => wrapFunctional(c.default || c))
export const ComparisonSticky = () => import('../../components/products/comparison/ComparisonSticky.vue' /* webpackChunkName: "components/comparison-sticky" */).then(c => wrapFunctional(c.default || c))
export const ComparisonWrapper = () => import('../../components/products/comparison/ComparisonWrapper.vue' /* webpackChunkName: "components/comparison-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FilterDatepicker = () => import('../../components/products/filter-components/FilterDatepicker.vue' /* webpackChunkName: "components/filter-datepicker" */).then(c => wrapFunctional(c.default || c))
export const FilterDatepickerMobile = () => import('../../components/products/filter-components/FilterDatepickerMobile.vue' /* webpackChunkName: "components/filter-datepicker-mobile" */).then(c => wrapFunctional(c.default || c))
export const FilterDisplayMobile = () => import('../../components/products/filter-components/FilterDisplayMobile.vue' /* webpackChunkName: "components/filter-display-mobile" */).then(c => wrapFunctional(c.default || c))
export const FilterDisplaySorting = () => import('../../components/products/filter-components/FilterDisplaySorting.vue' /* webpackChunkName: "components/filter-display-sorting" */).then(c => wrapFunctional(c.default || c))
export const FilterDropdown = () => import('../../components/products/filter-components/FilterDropdown.vue' /* webpackChunkName: "components/filter-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FilterDropdownAgeMobile = () => import('../../components/products/filter-components/FilterDropdownAgeMobile.vue' /* webpackChunkName: "components/filter-dropdown-age-mobile" */).then(c => wrapFunctional(c.default || c))
export const FilterDropdownMobile = () => import('../../components/products/filter-components/FilterDropdownMobile.vue' /* webpackChunkName: "components/filter-dropdown-mobile" */).then(c => wrapFunctional(c.default || c))
export const FilterInput = () => import('../../components/products/filter-components/FilterInput.vue' /* webpackChunkName: "components/filter-input" */).then(c => wrapFunctional(c.default || c))
export const FilterInputMobile = () => import('../../components/products/filter-components/FilterInputMobile.vue' /* webpackChunkName: "components/filter-input-mobile" */).then(c => wrapFunctional(c.default || c))
export const FilterMobile = () => import('../../components/products/filter-components/FilterMobile.vue' /* webpackChunkName: "components/filter-mobile" */).then(c => wrapFunctional(c.default || c))
export const FilterModal = () => import('../../components/products/filter-components/FilterModal.vue' /* webpackChunkName: "components/filter-modal" */).then(c => wrapFunctional(c.default || c))
export const FilterSelection = () => import('../../components/products/filter-components/FilterSelection.vue' /* webpackChunkName: "components/filter-selection" */).then(c => wrapFunctional(c.default || c))
export const FilterSelectionMobile = () => import('../../components/products/filter-components/FilterSelectionMobile.vue' /* webpackChunkName: "components/filter-selection-mobile" */).then(c => wrapFunctional(c.default || c))
export const FilterSlider = () => import('../../components/products/filter-components/FilterSlider.vue' /* webpackChunkName: "components/filter-slider" */).then(c => wrapFunctional(c.default || c))
export const FilterSliderMobile = () => import('../../components/products/filter-components/FilterSliderMobile.vue' /* webpackChunkName: "components/filter-slider-mobile" */).then(c => wrapFunctional(c.default || c))
export const FilterSortingMobile = () => import('../../components/products/filter-components/FilterSortingMobile.vue' /* webpackChunkName: "components/filter-sorting-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductFeature = () => import('../../components/products/product-components/ProductFeature.vue' /* webpackChunkName: "components/product-feature" */).then(c => wrapFunctional(c.default || c))
export const ProductFeatureCompact = () => import('../../components/products/product-components/ProductFeatureCompact.vue' /* webpackChunkName: "components/product-feature-compact" */).then(c => wrapFunctional(c.default || c))
export const ProductKeyItem = () => import('../../components/products/product-components/ProductKeyItem.vue' /* webpackChunkName: "components/product-key-item" */).then(c => wrapFunctional(c.default || c))
export const ProductKeyItemRevamp = () => import('../../components/products/product-components/ProductKeyItemRevamp.vue' /* webpackChunkName: "components/product-key-item-revamp" */).then(c => wrapFunctional(c.default || c))
export const ProductRating = () => import('../../components/products/product-components/ProductRating.vue' /* webpackChunkName: "components/product-rating" */).then(c => wrapFunctional(c.default || c))
export const ProductScore = () => import('../../components/products/product-components/ProductScore.vue' /* webpackChunkName: "components/product-score" */).then(c => wrapFunctional(c.default || c))
export const ProductScoreRevamp = () => import('../../components/products/product-components/ProductScoreRevamp.vue' /* webpackChunkName: "components/product-score-revamp" */).then(c => wrapFunctional(c.default || c))
export const SponsorPromotion = () => import('../../components/products/product-components/SponsorPromotion.vue' /* webpackChunkName: "components/sponsor-promotion" */).then(c => wrapFunctional(c.default || c))
export const ToggleFavouriteProduct = () => import('../../components/products/product-components/ToggleFavouriteProduct.vue' /* webpackChunkName: "components/toggle-favourite-product" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailRowsContent = () => import('../../components/products/product-detail/ProductDetailRowsContent.vue' /* webpackChunkName: "components/product-detail-rows-content" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailSection = () => import('../../components/products/product-detail/ProductDetailSection.vue' /* webpackChunkName: "components/product-detail-section" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailWrapper = () => import('../../components/products/product-detail/ProductDetailWrapper.vue' /* webpackChunkName: "components/product-detail-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ProductHighlightSection = () => import('../../components/products/product-detail/ProductHighlightSection.vue' /* webpackChunkName: "components/product-highlight-section" */).then(c => wrapFunctional(c.default || c))
export const ProductListingCard = () => import('../../components/products/product-listing/ProductListingCard.vue' /* webpackChunkName: "components/product-listing-card" */).then(c => wrapFunctional(c.default || c))
export const ProductListingCardRevamp = () => import('../../components/products/product-listing/ProductListingCardRevamp.vue' /* webpackChunkName: "components/product-listing-card-revamp" */).then(c => wrapFunctional(c.default || c))
export const ProductListingCardSkeleton = () => import('../../components/products/product-listing/ProductListingCardSkeleton.vue' /* webpackChunkName: "components/product-listing-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ProductListingCompactCard = () => import('../../components/products/product-listing/ProductListingCompactCard.vue' /* webpackChunkName: "components/product-listing-compact-card" */).then(c => wrapFunctional(c.default || c))
export const ProductListingCompactCardRevamp = () => import('../../components/products/product-listing/ProductListingCompactCardRevamp.vue' /* webpackChunkName: "components/product-listing-compact-card-revamp" */).then(c => wrapFunctional(c.default || c))
export const ProductListingDisplayModeSwitcher = () => import('../../components/products/product-listing/ProductListingDisplayModeSwitcher.vue' /* webpackChunkName: "components/product-listing-display-mode-switcher" */).then(c => wrapFunctional(c.default || c))
export const ProductListingMobileCard = () => import('../../components/products/product-listing/ProductListingMobileCard.vue' /* webpackChunkName: "components/product-listing-mobile-card" */).then(c => wrapFunctional(c.default || c))
export const ProductListingResultTopBar = () => import('../../components/products/product-listing/ProductListingResultTopBar.vue' /* webpackChunkName: "components/product-listing-result-top-bar" */).then(c => wrapFunctional(c.default || c))
export const ProductListingSectionHeaderWrapper = () => import('../../components/products/product-listing/ProductListingSectionHeaderWrapper.vue' /* webpackChunkName: "components/product-listing-section-header-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ProductListingWrapper = () => import('../../components/products/product-listing/ProductListingWrapper.vue' /* webpackChunkName: "components/product-listing-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceItem = () => import('../../components/products/product-listing/ProductPriceItem.vue' /* webpackChunkName: "components/product-price-item" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeBlogSection = () => import('../../components/qdap/home/qdap-home-blog-section.vue' /* webpackChunkName: "components/qdap-home-blog-section" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeBriefSection = () => import('../../components/qdap/home/qdap-home-brief-section.vue' /* webpackChunkName: "components/qdap-home-brief-section" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeCaseStudySection = () => import('../../components/qdap/home/qdap-home-case-study-section.vue' /* webpackChunkName: "components/qdap-home-case-study-section" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeConsultationSection = () => import('../../components/qdap/home/qdap-home-consultation-section.vue' /* webpackChunkName: "components/qdap-home-consultation-section" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeGuideSection = () => import('../../components/qdap/home/qdap-home-guide-section.vue' /* webpackChunkName: "components/qdap-home-guide-section" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeHeadSection = () => import('../../components/qdap/home/qdap-home-head-section.vue' /* webpackChunkName: "components/qdap-home-head-section" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeProductCardPromotionDetail = () => import('../../components/qdap/home/qdap-home-product-card-promotion-detail.vue' /* webpackChunkName: "components/qdap-home-product-card-promotion-detail" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeProductCardSponsorPromotion = () => import('../../components/qdap/home/qdap-home-product-card-sponsor-promotion.vue' /* webpackChunkName: "components/qdap-home-product-card-sponsor-promotion" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeProductCard = () => import('../../components/qdap/home/qdap-home-product-card.vue' /* webpackChunkName: "components/qdap-home-product-card" */).then(c => wrapFunctional(c.default || c))
export const QdapHomePromotionSection = () => import('../../components/qdap/home/qdap-home-promotion-section.vue' /* webpackChunkName: "components/qdap-home-promotion-section" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeSelectedSection = () => import('../../components/qdap/home/qdap-home-selected-section.vue' /* webpackChunkName: "components/qdap-home-selected-section" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeSelectionSection = () => import('../../components/qdap/home/qdap-home-selection-section.vue' /* webpackChunkName: "components/qdap-home-selection-section" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeVideoSectionDesktopComponent = () => import('../../components/qdap/home/qdap-home-video-section-desktop-component.vue' /* webpackChunkName: "components/qdap-home-video-section-desktop-component" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeVideoSectionMobileComponent = () => import('../../components/qdap/home/qdap-home-video-section-mobile-component.vue' /* webpackChunkName: "components/qdap-home-video-section-mobile-component" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeVideoSectionVideoCard = () => import('../../components/qdap/home/qdap-home-video-section-video-card.vue' /* webpackChunkName: "components/qdap-home-video-section-video-card" */).then(c => wrapFunctional(c.default || c))
export const QdapHomeVideoSection = () => import('../../components/qdap/home/qdap-home-video-section.vue' /* webpackChunkName: "components/qdap-home-video-section" */).then(c => wrapFunctional(c.default || c))
export const QdapProductsBannerSection = () => import('../../components/qdap/products/qdap-products-banner-section.vue' /* webpackChunkName: "components/qdap-products-banner-section" */).then(c => wrapFunctional(c.default || c))
export const QdapProductsButtonSection = () => import('../../components/qdap/products/qdap-products-button-section.vue' /* webpackChunkName: "components/qdap-products-button-section" */).then(c => wrapFunctional(c.default || c))
export const QdapProductsDisclaimerSection = () => import('../../components/qdap/products/qdap-products-disclaimer-section.vue' /* webpackChunkName: "components/qdap-products-disclaimer-section" */).then(c => wrapFunctional(c.default || c))
export const QdapProductsDocumentSection = () => import('../../components/qdap/products/qdap-products-document-section.vue' /* webpackChunkName: "components/qdap-products-document-section" */).then(c => wrapFunctional(c.default || c))
export const QdapProductsFeatureSection = () => import('../../components/qdap/products/qdap-products-feature-section.vue' /* webpackChunkName: "components/qdap-products-feature-section" */).then(c => wrapFunctional(c.default || c))
export const QdapProductsHeadSection = () => import('../../components/qdap/products/qdap-products-head-section.vue' /* webpackChunkName: "components/qdap-products-head-section" */).then(c => wrapFunctional(c.default || c))
export const QdapProductsImageSection = () => import('../../components/qdap/products/qdap-products-image-section.vue' /* webpackChunkName: "components/qdap-products-image-section" */).then(c => wrapFunctional(c.default || c))
export const QdapProductsRemarksSection = () => import('../../components/qdap/products/qdap-products-remarks-section.vue' /* webpackChunkName: "components/qdap-products-remarks-section" */).then(c => wrapFunctional(c.default || c))
export const QdapProductsTextSection = () => import('../../components/qdap/products/qdap-products-text-section.vue' /* webpackChunkName: "components/qdap-products-text-section" */).then(c => wrapFunctional(c.default || c))
export const QdapProductsVideoSection = () => import('../../components/qdap/products/qdap-products-video-section.vue' /* webpackChunkName: "components/qdap-products-video-section" */).then(c => wrapFunctional(c.default || c))
export const NavigationContentAboutUs = () => import('../../components/navigation-bar/navigation-content/modules/NavigationContentAboutUs.vue' /* webpackChunkName: "components/navigation-content-about-us" */).then(c => wrapFunctional(c.default || c))
export const NavigationContentBroker = () => import('../../components/navigation-bar/navigation-content/modules/NavigationContentBroker.vue' /* webpackChunkName: "components/navigation-content-broker" */).then(c => wrapFunctional(c.default || c))
export const NavigationContentDecoder = () => import('../../components/navigation-bar/navigation-content/modules/NavigationContentDecoder.vue' /* webpackChunkName: "components/navigation-content-decoder" */).then(c => wrapFunctional(c.default || c))
export const NavigationContentLifestage = () => import('../../components/navigation-bar/navigation-content/modules/NavigationContentLifestage.vue' /* webpackChunkName: "components/navigation-content-lifestage" */).then(c => wrapFunctional(c.default || c))
export const NavigationContentCardVisual = () => import('../../components/navigation-bar/navigation-content/sections/NavigationContentCardVisual.vue' /* webpackChunkName: "components/navigation-content-card-visual" */).then(c => wrapFunctional(c.default || c))
export const NavigationContentCompanyInfo = () => import('../../components/navigation-bar/navigation-content/sections/NavigationContentCompanyInfo.vue' /* webpackChunkName: "components/navigation-content-company-info" */).then(c => wrapFunctional(c.default || c))
export const NavigationContentIntro = () => import('../../components/navigation-bar/navigation-content/sections/NavigationContentIntro.vue' /* webpackChunkName: "components/navigation-content-intro" */).then(c => wrapFunctional(c.default || c))
export const NavigationContentLinks = () => import('../../components/navigation-bar/navigation-content/sections/NavigationContentLinks.vue' /* webpackChunkName: "components/navigation-content-links" */).then(c => wrapFunctional(c.default || c))
export const ComparisonMobileTopProductCardSection = () => import('../../components/products/comparison/Card/ComparisonMobileTopProductCardSection.vue' /* webpackChunkName: "components/comparison-mobile-top-product-card-section" */).then(c => wrapFunctional(c.default || c))
export const ComparisonTopProductCardSection = () => import('../../components/products/comparison/Card/ComparisonTopProductCardSection.vue' /* webpackChunkName: "components/comparison-top-product-card-section" */).then(c => wrapFunctional(c.default || c))
export const StickyComparisonTopProductCardSection = () => import('../../components/products/comparison/Card/StickyComparisonTopProductCardSection.vue' /* webpackChunkName: "components/sticky-comparison-top-product-card-section" */).then(c => wrapFunctional(c.default || c))
export const ComparisonContentSection = () => import('../../components/products/comparison/Content/ComparisonContentSection.vue' /* webpackChunkName: "components/comparison-content-section" */).then(c => wrapFunctional(c.default || c))
export const ComparisonItemCell = () => import('../../components/products/comparison/Content/ComparisonItemCell.vue' /* webpackChunkName: "components/comparison-item-cell" */).then(c => wrapFunctional(c.default || c))
export const ComparisonMobileContentSection = () => import('../../components/products/comparison/Content/ComparisonMobileContentSection.vue' /* webpackChunkName: "components/comparison-mobile-content-section" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
