export default {
  mounted() {
  },
  
  created() {
    this.trackQS()
  },

  methods: {
    trackQS() {
      /** ads and lgmads are the id for tracking ad conversion to whatsapp */
      const adsId = this.$route.query.ads;
      const lgmadsId = this.$route.query.lgmads;
      if (adsId || lgmadsId) {
        this.removeCookies('_10life_ads');
        this.removeCookies('_10life_lgmads');
        if (adsId) {
          this.setCookies('_10life_ads', adsId, 7);
        }
        if (lgmadsId) {
          this.setCookies('_10life_lgmads', lgmadsId, 7);
        }
      }
    },
    getCookies(key) {
      return this.$cookies.get(key);
    },
    setCookies(key, value, days) {
      const maxAgeInSecond = days * 24 * 60 * 60;
      this.$cookies.set(key, value, { 
        maxAge: maxAgeInSecond,
        path: '/', });
    },
    removeCookies(key) {
      this.$cookies.remove(key);
    }
  },
}