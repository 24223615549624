  
  import functionBarQuery from '~/apollo/queries/function-bar/functionBar';

  export default {
    name: 'FunctionBar',
  
    apollo: {
      $loadingKey: 'loading',
      functionBars: {
        query: functionBarQuery,
        prefetch: true,
        variables() {
          return { currentlocale: this.$normalizeLocale(this.$i18n.locale) };
        },
        update(data) {
          return this.$normalizeStrapi(data.functionBars.data);
        }
      }
    },

    data() {
      return {
        loading: 0,
        functionBarActive: false,
        currentLocale: this.$normalizeLocale(this.$i18n.locale),
        functionBars: null,
        isAdvisorBlog: false,
      }
    },

    computed: {
      sortedFunctionBars() {
        if(!this.loading) {
          return this.functionBars?.filter((item) => item.show === true).slice().sort((a, b) => a.index - b.index);
        }
        else {
          return null;
        }
      }
    },
    
    methods: {
      jumpToTop() {
        window.scrollTo(0, 0);
      },
    },

    mounted() {
        // check if the blog is advisor blog or not
        const urlParams = new URLSearchParams(window.location.search);
        this.isAdvisorBlog = urlParams.has('adid');
    },
  };
  