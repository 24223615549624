
import eventTrackingMixins from '~/mixins/eventTrackingMixins.js';
import whatsappFn from '~/mixins/whatsappFn.js';
import whatsappQuery from '@/apollo/queries/general/whatsappQuery'

export default {
  name: 'WhatsappFloatingWidget',
  
  mixins: [eventTrackingMixins, whatsappFn],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      whatsappUrl: 'https://api.whatsapp.com/send/?phone=85237051599&text=',
    }
  },

  computed: {
  },

  methods: {
    async openWhatsapp() {
      const msgObj =
        this.whatsappMsg.find((item) => this.getPlacementCodeByRouteName() === item.PlacementCode) ||
        this.whatsappMsg.find((item) => this.getPlacementCodeByRouteName().startsWith(item.PlacementCode)) ||
        this.whatsappMsg.find((item) => item.PlacementCode === 'general');
      let msg = msgObj?.Msg || '';

      if (this.getPlacementCodeByRouteName() === 'blog-single') {
        const blogUrl = location.toString().split('?')[0];
        const firstBlogCategoryDisplayName = this.getBlogFirstTag();
        msg = msg
          ?.replace('{blog_url}', blogUrl)
          .replace('{first_blog_category_display_name}', firstBlogCategoryDisplayName);
      }

      if (this.getPlacementCodeByRouteName() === 'media-centre') {
        const mediaUrl = location.toString().split('?')[0];
        msg = msg
          ?.replace('{media_url}', mediaUrl);
      }

      if (this.getPlacementCodeByRouteName().startsWith('scoring-methodology')) {
        const methodologyUrl = location.toString().split('?')[0];
        msg = msg
          ?.replace('{methodology_url}', methodologyUrl);
      }

      if (this.getPlacementCodeByRouteName().startsWith('five-star')) {
        const fiveStarUrl = location.toString().split('?')[0];
        msg = msg
          ?.replace('{5star_url}', fiveStarUrl);
      }

      if (this.getPlacementCodeByRouteName().startsWith('family-site')) {
        const familySiteUrl = location.toString().split('?')[0];
        msg = msg
          ?.replace('{kid_url}', familySiteUrl);
      }

      const applyAdTrackingToWhatsappLink = async (link) => {
        const btnLink = this.whatsappUrl; 
        try {
          if(this.isWhatsappLink(btnLink) && (this.hasAdsInCookies() || this.hasLgmadsInCookies())) {
            const refData = await this.fetchWhatsappRefCode()
            window.open(this.adjustWhatsappLink(btnLink, refData) + encodeURIComponent(msg), this.$device.isMobile ? '_self' : '_blank'); 
            return true;
          }
          return false
        } catch (error) {
          console.error('error: ', error)
        }
      }
      if(!await applyAdTrackingToWhatsappLink()) {
        window.open(`${this.whatsappUrl}${encodeURIComponent(msg)}`);
      }

      this.handleEventTracking();
    },
    
    getBlogFirstTag() {
      const tag = document.querySelector('.blog-single .tags .tag');
      return tag?.innerText ? tag.innerText : '';
    },
    handleEventTracking() {
      const url = location.href;
      const patternDecoder = /^.*\/products\/([-a-zA-Z\d]+)((?!\/compare).)*$/g;
      const patternComparison = /^.*\/products\/([-a-zA-Z\d/]+)[/]compare[/]?[?]*(.*)$/;
      if(patternDecoder.test(url) && /^products-(?!.*-slug).*$/.test(this.getRouteName())) {
        // #74 decoder_widget
        const eventTrackingParams = this.initEventTrackingParams()

        const url = location.href;
        const productData = this.extractDataFromProductListingLink(url)
        const insuranceCompany = productData.insurer_code
        const productScenarioCode = productData.scenario_code
        const productName = productData.product_name
        const productCategory = productData.category
        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  productScenarioCode
        eventTrackingParams.c = `decoder_widget`
        eventTrackingParams.cd.insurer_name = insuranceCompany
        eventTrackingParams.cd.product_name = productName
        eventTrackingParams.cd.product_category = productCategory
      
        this.sendEventTracking(eventTrackingParams)

      } else if(/^(products-).+(-slug)$/.test(this.getRouteName())) {
        // #87 product_details_widget
        const eventTrackingParams = this.initEventTrackingParams()

        const productData = this.extractDataFromCurrentProductDetailPage()
        const insuranceCompany = productData.product.insurer_name
        const productScenarioCode = productData.product.scenario_code
        const productName = productData.product.product_name
        const productCategory = productData.product.product_category
        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  productScenarioCode
        eventTrackingParams.c = `product_details_widget`
        eventTrackingParams.cd.insurer_name = insuranceCompany
        eventTrackingParams.cd.product_name = productName
        eventTrackingParams.cd.product_category = productCategory
      
        this.sendEventTracking(eventTrackingParams)
      } else if(/^(5star-products)(.)*$/.test(this.getRouteName())) {
        // #181 open_whatsapp 5star_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `5star_widget`
        eventTrackingParams.cd.insurer_name = undefined
        eventTrackingParams.cd.product_name = undefined
        eventTrackingParams.cd.product_category = undefined
      
        this.sendEventTracking(eventTrackingParams)
      } else if(/^(hong-kong-insurance)/.test(this.getRouteName())) {
        // #170 open_whatsapp drifter_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `drifter_widget`
        eventTrackingParams.cd.insurer_name = undefined
        eventTrackingParams.cd.product_name = undefined
        eventTrackingParams.cd.product_category = undefined

        this.sendEventTracking(eventTrackingParams)
      } else if(/^(insurer)(.)*$/.test(this.getRouteName())) {
        // #224 open_whatsapp Insurer_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `Insurer_widget`
        eventTrackingParams.cd.insurer_name = undefined
        eventTrackingParams.cd.product_name = undefined
        eventTrackingParams.cd.product_category = undefined

        this.sendEventTracking(eventTrackingParams)
      } else if (this.getPlacementCodeByRouteName() === 'five-star-main' || this.getPlacementCodeByRouteName() === 'five-star-insurer' || this.getPlacementCodeByRouteName() === 'five-star-category' ) {
        // #64 5star_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `5star_widget`
      
        this.sendEventTracking(eventTrackingParams)
      } else if (this.getPlacementCodeByRouteName() === 'blog-single' || this.getPlacementCodeByRouteName() === 'blog-listing') {
        // #67 blog_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `blog_widget`
      
        this.sendEventTracking(eventTrackingParams)
      } else if (this.getPlacementCodeByRouteName() === 'my-favourite') {
        // #84 my_save_page_widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `my_save_page_widget`
      
        this.sendEventTracking(eventTrackingParams)
      } else if (patternComparison.test(url)) {
        // #138 comparison_widget
        const productData = this.extractDataFromCurrentProductComparisonPage()
        const productCategory = productData.product.product_category
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `comparison_widget`
        eventTrackingParams.cd.product_category = productCategory
      
        this.sendEventTracking(eventTrackingParams)
      } else if (this.getPlacementCodeByRouteName() === 'qdap-mall-homepage') {
        // #204 QDAP Mall - Landing Page Whatsapp Widget
        const eventTrackingParams = this.initEventTrackingParams()

        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = `qdap_widget`
      
        this.sendEventTracking(eventTrackingParams)
      } else {
        // #69 #73 #79 #81 #85
        const params = {}
        params.categoryPrefix = `${this.getEventTrackingLocationName()}_`

        const eventTrackingParams = this.initEventTrackingParams()
        eventTrackingParams.a = 'open_whatsapp'
        eventTrackingParams.l =  undefined
        eventTrackingParams.c = params.categoryPrefix + 'widget'

        this.sendEventTracking(eventTrackingParams)
      }
    },
  },
  
  apollo: {
    $loadingKey: 'loading',
    whatsappMsg: {
      prefetch: true,
      query: whatsappQuery,
      variables() {
        return { currentlocale: this.$normalizeLocale(this.$i18n.locale) };
      },
      update(data) {
        return this.$normalizeStrapi(data.whatsappWidgetItems);
      },
    },
  },
};
