export default {
  data() {
    return {
      middlePlatformUrl: process.env.MIDDLE_PLATFORM_URL,  
    };
  },
  methods: {
    getUid() {
      const gaID = this.$cookies.get('_ga');
      const match = gaID.match(/.*\.[\d]\.(\d+\.\d+)$/);
      return match[1];
    },
    async fetchWhatsappRefCode() {
      const uid = this.getUid();
      const adsId = this.getAdsId();
      const lgmadsId = this.getLgmadsId();
      try {
        if(!uid && !adsId && !lgmadsId) throw new Error('Params missing. uid, adsId and lgmadsId are missing.')
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        const delayed = async () => {
          await delay(10);

          const adsIdQS = adsId ? `&ad_tracking_id=${adsId}` : '';
          const lgmadsIdQS = lgmadsId ? `&ad_param=${lgmadsId}` : ''; 
          const uidQS = uid ? `&user_pseudo_id=${uid}` : '';

          const res = await fetch(`${this.middlePlatformUrl}/integration/ad-tracking/hash?tracking${uidQS}${adsIdQS}${lgmadsIdQS}`)
          
          if (res.status !== 200) {
            throw new Error('Response is not 200')
          }
          const data = await res.json()
          return data?.hash
        }
        return await delayed()

      } catch (error) {
        console.error('error: ', error)
      }
      return null
    },
    getAdsId() {
      return this.$cookies.get('_10life_ads')
    },
    getLgmadsId() {
      return this.$cookies.get('_10life_lgmads')
    },
    hasAdsInCookies() {
      if(this.$cookies.get('_10life_ads')) {
        return true
      }
      return false
    },
    hasLgmadsInCookies() {
      if(this.$cookies.get('_10life_lgmads')) {
        return true
      }
      return false
    },
    isWhatsappLink(link) {
      const boo = link && (link.includes('api.whatsapp.com') || link.includes('https://wa.me'))
      return boo
    },
    adjustWhatsappLink(link, refText) {
      if (this.isWhatsappLink(link)) {
        return this.prependAdRefToWhatsappText(link, refText);
      }
      return link;
    },
    prependAdRefToWhatsappText(whatsappLink, refString) {
      if(this.hasAdsInCookies() || this.hasLgmadsInCookies()) {
        if(!refString) refString = '404' 
        const link = whatsappLink?.replace('text=', (`text=<10Life Ref:${refString}> `))
        return link
      }
      return whatsappLink
    },
  }
}