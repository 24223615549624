import productService from '~/services/productService'
// import authGateway from '~/services/authGateway'
import leadformGateway from '~/services/leadformGateway'
import contactusformGateway from '~/services/contactusformGateway'
import strapiGateway from '~/services/strapiGateway'
import advisorGateway from '~/services/advisorGateway'

export default ({ app, $axios }, inject) => {
  const i18n = app.i18n;
  const allMethods = {
    ...productService(i18n),
    // ...authGateway($axios),
    ...leadformGateway($axios),
    ...contactusformGateway($axios),
    ...strapiGateway($axios),
    ...advisorGateway($axios)
    // import another service here
  };
  const methods = Object.keys(allMethods);
  methods.forEach((method) => {
    inject(method, allMethods[method])
  });
}