

export default {
  name: 'EnquiryFloatingWidget',
  

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      fabActive: false,
    }
  },

  computed: {
    enqueryImgPath() {
      return  require(`@/assets/images/enquiry/enquiry-fab-${this.$i18n.locale}.svg`)
    },
    qrCodeActiveStyle() {
      return {bottom: '0', height: 'auto', position: 'relative'}
    }
  },

  methods: {
    setFabActive(boolean) {
      this.fabActive = boolean;
    },
  },
};
