
import { OpenIDConnectScheme } from '~auth/runtime';
import axios from 'axios';

/**
 * Tenlife use a custom scheme extends OpenIDConnectScheme, The schemes run with backend from nuxtjs
 * The flow has been customized, as oauth credential is stored in backend to authenticate from tenlife oauth2 server (SSO), 
 * Nuxt communicated with strapi with the jwt obtained in the login/renew token process
 * 
 * Special handling
 * - login, will ignore all configuration and trigger strapi tenlife provider directly.
 * - callback, access_token, refresh_token & id token from tenlife oauth (openid connect) will be store
 * - exchanged strapi jwt store as apollo-token
 * - refresh token or check login, use access token and refresh token for new token from oauth server
 * - @nuxt/auth-next may need to renew id token if expired, however, it should not related to refresh token
 */
export default class TenlifeScheme extends OpenIDConnectScheme {

  /**
   * Single Sign On (SSO)
   * by-pass to use strapi oauth provider
   */
  async login (_opts = {}){
    try {
      const from = this.options.fullPathRedirect
      ? this.$auth.ctx.route.fullPath
      : this.$auth.ctx.route.path;
      const query = this.$auth.ctx.route.query;
      let redirectUrl = from;
      if (Object.keys(query).length !== 0 && query.constructor === Object) {
        const queryString = this.encodeQuery(query);
        redirectUrl += '?' + queryString;
      }
      const callback = this.$auth.options.redirect.callback;
      const pattern = "^\/(en|zh-HK)?\/?" + callback + "$"; // escape the slashes and use ^ and $ to anchor the pattern
      const flags = "i"; // use i flag for case-insensitive matching
      const regex = new RegExp(pattern, flags);
      // when SSR is enabled, path is always attachedk with i18n lang prefix, regex should match all lang prefix and without lang prefix.
      if (this.$auth.options.redirect && regex.test(this.$auth.ctx.route.path)){
        console.log("current url is a callback, do not set callback as redirect path");
        redirectUrl = "/";
      }
      this.$auth.$storage.setUniversal('redirect', redirectUrl);
    } catch (error) {
      console.log("save login path error:", error);  
    }
    const register = _opts?.params?.register;
    const authorization_endpoint = this.options.endpoints.login.authorization;
    if(authorization_endpoint){
      let custom_params = {};
      if(register){
        custom_params.prompt = "create";
      }
      // map application locale to odic supported lang
      const locale = this.$auth.ctx.i18n.locale;
      let ui_locale = 'zh-HK';
      if(locale == 'en'){
        ui_locale = 'en';
      } else if(locale == 'zh-HK'){
        ui_locale = 'zh-HK';
      }
      custom_params.ui_locale = ui_locale;
      if (Object.keys(custom_params).length !== 0) {
        const custom_params_query_string = this.encodeQuery(custom_params);
        window.location.href = authorization_endpoint + '?' + custom_params_query_string;
      } else {
        window.location.href = authorization_endpoint;
      }
    }
  }

  /**
   * Override original normalizePath preserve query parameter
   * @param {*} path 
   * @param {*} ctx 
   * @returns 
   */
  normalizePath(path = '', ctx) {
    // Remove query string
    let result = path;  
    // Remove base path
    if (ctx && ctx.base) {
      result = result.replace(ctx.base, '/')
    }
    // Remove redundant / from the end of path
    if (result.charAt(result.length - 1) === '/') {
      result = result.slice(0, -1)
    }
    // Remove duplicate slashes
    result = result.replace(/\/+/g, '/')
    return result
  }

  /**
   * Helper function
   * @param {} queryObject 
   * @returns 
   */
  encodeQuery(queryObject){
    return Object.entries(queryObject)
      .filter(([_key, value]) => typeof value !== 'undefined')
      .map(
        ([key, value]) =>
          encodeURIComponent(key) +
          (value != null ? '=' + encodeURIComponent(value) : '')
      )
      .join('&')
  }

  removeBackendToken(){
    this.$auth.$storage.removeUniversal(this.options.backendToken.property);
  }

  setBackendToken(jwt){
    this.$auth.$storage.setUniversal(this.options.backendToken.property, jwt);
  }

  getBackendToken(){
    return this.$auth.$storage.getUniversal(this.options.backendToken.property);
  }

  getProp(holder,propName) {
    if (!propName || !holder || typeof holder !== 'object') {
      return holder
    }
  
    if (propName in holder) {
      return holder[propName]
    }
  
    const propParts = Array.isArray(propName)
      ? propName
      : (propName + '').split('.')
  
    let result = holder
    while (propParts.length && result) {
      result = result[propParts.shift()]
    }
  
    return result
  }

  /** 
   * Single Logout (SLO)
   */
  logout(_opts = {}) {
    const logout_from_sso = _opts?.params?.sso;
      this.removeBackendToken();
      const opts = {
        id_token_hint: this.idToken.get(),
        post_logout_redirect_uri: this.options.logoutRedirectUri,
      }
      if(logout_from_sso){
        opts.logout_from_sso = true;
      }
      // get end_session_endpoint from odic discovery
      const end_session_endpoint = this.configurationDocument.get().end_session_endpoint;
      const url = end_session_endpoint + '?' + this.encodeQuery(opts);
      this.customResetToken();
      window.location.replace(url);
  }

  /**
   * Since this.$auth.reset() or any token reset() will trigger a requestHander reset that reset axios inteceptor, which may have conflict between node_backend configuration
   * The reason is still unknown, but in nuxtjs 2.x proven that it will generate a 404 page not found.
   * We need to write a customResetToken function to reset all token.
   */
  customResetToken() {
    console.log("debug custom reset token");
    this.token._setToken(false);
    this.token._setExpiration(false);
    this.refreshToken._setToken(false);
    this.refreshToken._setExpiration(false);
    this.idToken._setToken(false);
    this.idToken._setExpiration(false);
    this.removeBackendToken();
  }

  reset(){
    console.log("override reset");
    this.token._setToken(false);
    this.token._setExpiration(false);
    this.refreshToken._setToken(false);
    this.refreshToken._setExpiration(false);
    this.idToken._setToken(false);
    this.idToken._setExpiration(false);
    this.removeBackendToken();
  };

  /**
   * fetchUser in pages, run after callback
   * @param {*} endpoint 
   * @returns 
   */
  async fetchUser (endpoint) {
    if (process.server) {
      return
    }
    // Token is required but not available
    if (!this.check().valid) {
      return
    }
    // User endpoint is disabled.
    if (!this.options.endpoints.login.userInfo) {
      this.$auth.setUser({});
      return
    }
    // intecept the auth get jwt token from strapi
    let customUser = {
    }
    // get user from backend
    const token = this.getBackendToken();
    return axios.get(this.options.endpoints.login.userInfo, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response =>{
      // console.log(response.data);
      const user = response.data;
      customUser = {
        ...user,
      }
      this.$auth.setUser(customUser);
    })
    .catch((error) => {
      this.$auth.callOnError(error, { method: 'fetchUser' })
    });
  }

  async _handleCallback() {
    // Callback flow is not supported in server side
    if (process.server) {
      return
    }
    const callback = this.$auth.options.redirect.callback;
    /**
     * This is a bad idea to match with two lang, what if a new lang added?
     */
    const pattern = "^\/(en|zh-HK)?\/?" + callback + "$"; // escape the slashes and use ^ and $ to anchor the pattern
    const flags = "i"; // use i flag for case-insensitive matching
    const regex = new RegExp(pattern, flags);
    // when SSR is enabled, path is always attachedk with i18n lang prefix, regex should match all lang prefix and without lang prefix.
    if (this.$auth.options.redirect && regex.test(this.$auth.ctx.route.path)){
      const error = this.$auth.ctx.route.query?.error;
      if(error){
        // error handling redirect back to main
        this.$auth.redirect('home', true)
      }
    } else {
      return
    }
    // accessToken/idToken
    const idToken = this.$auth.ctx.route.query[this.options.idToken.property];
    const token = this.$auth.ctx.route.query[this.options.token.property];
    const refreshToken = this.$auth.ctx.route.query[this.options.refreshToken.property];
    // backend token
    try {
      const res = await axios.get(this.options.endpoints.login.backend, {
        params: { access_token: token },
        withCredentials: true, 
      });
      if (res.data.jwt) {
        this.setBackendToken(res.data.jwt);
      }
      this.$auth.$storage.setUniversal('session_state', res.data?.session_state);
      this.$auth.$storage.setUniversal('client_id', res.data?.client_id);
    } catch (err) {
      // consider to be failed if strapi jwt is not accessible
      console.error("SSO:" ,err);
      return;
    }
    if (!token || !token.length) {
      return
    }
    // Set token
    this.token.set(token)
    // Store refresh token
    if (refreshToken && refreshToken.length) {
      this.refreshToken.set(refreshToken)
    }
    if (idToken && idToken.length) {
      try {
        this.idToken.set(idToken)        
      } catch (error) {
        console.log("SSO:", error);
      }
    }
    // Redirect to home
    if (this.$auth.options.watchLoggedIn) {
      const from = this.$auth.$storage.getUniversal('redirect');
      this.$auth.$storage.setUniversal('redirect', null);
      let to = '/' + this.$auth.ctx.base;
      if(from){ // Check if 'from' is not null or undefined
        try {
          to += '/' + from;
        } catch (error) {
          console.log("tenlifeScheme redirect error:", error);        
        }  
      }
      to = this.normalizePath(to);
      window.location.replace(to);
      return true // True means a redirect happened
    }
  }

  removeTokenPrefix(token,tokenType){
    if (!token || !tokenType || typeof token !== 'string') {
      return token
    }
    return token.replace(tokenType + ' ', '')
  }

  async refreshTokens(){
   // console.log("debug refresh token is running");
    // Get refresh token
    const refreshToken = this.refreshToken.get()
    // Refresh token is required but not available
    if (!refreshToken) {
      this.removeBackendToken();
      return
    }
    // Get refresh token status
    const refreshTokenStatus = this.refreshToken.status()
    // Refresh token is expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      this.customResetToken();
      throw new ExpiredAuthSessionError()
    }
    // Delete current token from the request header before refreshing
    this.requestHandler.clearHeader();
    const client_id = this.$auth.$storage.getUniversal('client_id');
    const data =  this.encodeQuery({
      refresh_token: this.removeTokenPrefix(
        refreshToken,
        this.options.token.type
      ),
      scope: 'openid profile',
      client_id,
      grant_type: 'refresh_token'
    });
    console.log("debug refresh token result: ", data);
    const token_endpoint = this.configurationDocument.get().token_endpoint;
    const response = await axios.post(token_endpoint, data, { headers: {'Content-Type': 'application/x-www-form-urlencoded'} })
      .catch(error => {
        this.removeBackendToken();
        this.$auth.callOnError(error, { method: 'refreshToken' })
        return Promise.reject(error)
      });
    if(!response){
      this.customResetToken();
      throw new ExpiredAuthSessionError()
    }
    this.updateTokens(response)
    // refresh apollo token
    try {
      const token = this.getProp(response.data, this.options.token.property);
      const res = await axios.get(this.options.endpoints.login.backend, {
        params: { access_token: token },
      });
      if (res.data.jwt) {
        this.setBackendToken(res.data.jwt);
      }
    } catch (err) {
      // consider to be failed if strapi jwt is not accessible
      console.error("debug:", err);
      return;
    }
    return response
  }
}